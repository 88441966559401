import { IPersonaProps } from '@fluentui/react';

import { bonifyEmailField } from '../Utils/IntegrationUtils';
import { UserRole } from './UserRole';

export interface BaseUser {
  id: string;
  name: string;
  email: string;
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  creationDate: string;
  countries: Country[];
  roles: UserRole[];
}

export interface Country {
  countryCode: string;
  countryDescription: string;
}

export const IsAdmin = (user: IUser) => {
  return user.roles.some((x) => x === UserRole.Admin);
};

export interface IPersonaOptionDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  creationDate: string;
  lastLogin: string;
}
export interface GraphUser {
  displayName: string;
  givenName: string;
  surname: string;
  onPremisesSamAccountName: string;
  mail: string;
  userPrincipalName: string;
}

export const userToPersonas = (users: BaseUser[]): IPersonaProps[] => {
  return users.map(
    (u) =>
      ({
        key: u.id,
        text: u.name,
        secondaryText: bonifyEmailField(u.email),
      } as IPersonaProps)
  );
};

export const userToPersona = (user: BaseUser): IPersonaProps => {
  return {
    key: user.id,
    text: user.name,
    secondaryText: bonifyEmailField(user.email),
  } as IPersonaProps;
};

export const personaToUser = (persona: IPersonaProps): BaseUser => {
  return {
    id: persona.key?.toString(),
    name: persona.text,
    email: persona.secondaryText,
  };
};
