import { SpudLetterDto } from "./SpudLetterDto";

export const getCompleteField = (field: keyof Partial<SpudLetterDto>) =>
	`${propertyToFieldNameMap[field]} (${fieldToSystemMap[field]})`;

//{ [K in keyof Partial<SpudLetterDto>]: any }
export const fieldToSystemMap: Partial<Record<keyof SpudLetterDto, string>> = {
	wellName: "Xware",
	country: "Xware",
	ownership: "EWR",
	blockName: "Xware",
	operator: "Xware",
	partnerships: "Xware",
	onshoreOffshore: "Xware",
	eniOperated: "Xware",
	path: "Xware",
	arisField: "Xware",
	prospect: "Xware",
	reservoirField: "Xware",
	reservoirLevel: "Xware",
	estimatedTargets: "ExpBMT",
	hiip: "ExpBMT",
	pos: "ExpBMT",
	trapType: "ExpBMT",
	formationAge: "ExpBMT",
	lithology: "ExpBMT",
	hydrocarbonPhase: "ExpBMT",
	rig: "RigView",
	rigContractor: "RigView",
	wcei: "EWR",
};

export const propertyToFieldNameMap: { [key in keyof Partial<SpudLetterDto>]: string } = {
	xwareTight: "Tight",
	userTight: "Tight",
	place: "Place",
	date: "Date",
	from: "From",
	wellCode: "Well Code",
	wellName: "Well Name",
	referenceSystem: "Reference System",
	ownership: "Ownership",
	country: "Country",
	blockName: "Block Name / License",
	operator: "Operator (Eni Shares)",
	partnerships: "Partnership",
	eniOperated: "Eni Operated",
	onshoreOffshore: "Onshore/Offshore",
	arisField: "Aris Field name",
	prospect: "Prospect",
	estimatedTargets: "Estimated Target",
	hiip: "HIIP [Mboa]",
	pos: "POS [%]",
	trapType: "Trap Type",
	formationAge: "Formation Age",
	lithology: "Lithology ",
	reservoirField: "Reservoir Field",
	reservoirLevel: "Reservoir Level(s)",
	hydrocarbonPhase: "Estimated main fluid",
	// mainFluid: 'Fluid details',
	// estimatedTargets: [],
	spudDate: "Spud date",
	rig: "Rig",
	rigContractor: "Rig Contractor",
	productionType: "Production Type",
	path: "Path",
	waterDepth: "Water Depth/Ground Level",
	rotaryTable: "Rotary Table",
	// selectedCoordinateFormat: 'Format',
	// selectedCoordinateType: 'Coordinates type',
	// latitude: 'Latitude',
	// longitude: 'Longitude ',
	coordinateReferenceSystem: "Coordinate Reference System",
	coordinateAdditionalInfo: "",

	plannedTVDSS: "Planned Total Depth/TVDSS",
	plannedMD: "Planned Total Depth/MD",
	afeCostType: "AFE Cost ",
	afeCostValue: "",
	afeDays: "AFE Days",
	wcei: "WCEI",
	notes: "Notes",
};
