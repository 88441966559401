import "./PendingChangesManager.scss";

import { useEffect, useState } from "react";

import { Icon, IconButton, Popup } from "@fluentui/react";

import { useLoaderContext } from "../../Context/LoaderContext";
import { useFormContext } from "../../Pages/FormLayout/FormContext";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import WindowToast from "../../Utils/WindowToast";
import LabelButton from "../LabelButton/LabelButton";
import PendingChangesModal from "../PendingChangesModal/PendingChangesModal";

export interface LatestExternalData {
    id: string;
    wellCode: string;
    fieldName: string;
    previousValue: any;
    currentValue: any;
    updateDate: Date;
}

export interface PendingChange extends LatestExternalData {
    selected: boolean;
}

const PendingChangesManager = () => {
    const { formState, setFormState, refreshSpudLetter } = useFormContext();
    const loader = useLoaderContext();

    const [showPendingChangesModal, setShowPendingChangesModal] = useState<boolean>(false);
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [pendingChanges, setPendingChanges] = useState<PendingChange[]>([]);

    useEffect(() => {
        if (!formState.wellCode) return;
        // loader.show();
        ApiService.LatestExternalDataController.getChanges(formState.wellCode)
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw "Error checking for external changes";

                let changes = response.payload as LatestExternalData[];
                if (changes.length > 0) {
                    setPendingChanges(changes.map((x) => ({ ...x, selected: false })));
                    setIsPopupVisible(true);
                }
            })
            .catch(WindowToast.error);
        // .finally(loader.hide);
    }, []);

    const closeModal = () => {
        setShowPendingChangesModal(false);
        setIsPopupVisible(false);
    };

    const onAcceptPendingChanges = () => {
        let selectedFields = pendingChanges.filter((x) => x.selected).map((x) => x.fieldName);
        if (selectedFields.length > 0) {
            loader.show();
            ApiService.SpudLetterController.applyChanges(formState.wellCode, selectedFields)
                .then((response: IAPIResponse) => {
                    if (response.error !== null) throw "There was an error applying the changes";
                    refreshSpudLetter();
                })
                .catch(WindowToast.error)
                .finally(() => {
                    loader.hide();
                    closeModal();
                });
        }
    };

    return (
        <>
            <div className="pending-changes-modal-wrapper">
                <PendingChangesModal
                    pendingChanges={pendingChanges}
                    setPendingChanges={setPendingChanges}
                    show={showPendingChangesModal}
                    onAccept={onAcceptPendingChanges}
                    onAbort={closeModal}
                />
            </div>
            {isPopupVisible && (
                <Popup
                    className={"pending-changes-popup"}
                    role="dialog"
                    aria-modal="true"
                    onDismiss={() => setIsPopupVisible(false)}
                >
                    <div className="pending-changes-content">
                        <Icon iconName="Refresh" />
                        <span>There are fields that need to be updated</span>
                        <LabelButton
                            darkBackground
                            text={`Update Fields (${pendingChanges.length})`}
                            onClick={() => setShowPendingChangesModal(true)}
                        />
                        <IconButton
                            className="pending-changes-button"
                            iconProps={{ iconName: "Cancel" }}
                            ariaLabel="Close popup modal"
                            onClick={() => setIsPopupVisible(false)}
                        />
                    </div>
                </Popup>
            )}
        </>
    );
};

export default PendingChangesManager;
