import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { uniq } from "underscore";

import { IDropdownOption, ITag } from "@fluentui/react";

import { BMT_PHASE_TO_XWARE_PHASE } from "../../Constants/Constants";
import { FormSections } from "../../Constants/FormSections";
import {
  CountryReservoirLevel,
  HydrocarbonPhase,
  IArisField,
  Prospect,
  ProspectData,
  ReservoirField,
  ReservoirLevel,
  TargetData,
} from "../../Models/ApiTypes";
import { ITargetItem } from "../../Models/ITargetItem";
import { SpudLetterDto } from "../../Models/SpudLetterDto";
import { FormContext } from "../../Pages/FormLayout/FormContext";
import { FormActions } from "../../Reducers/Form/FormActions";
import { getInputInfoValue } from "../../Reducers/Generic/GenericAction";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { FormMode } from "../../Utils/FormMode";
import { useInputOptions } from "../../Utils/Hooks";
import { capitalize, getValueFromOptions } from "../../Utils/Methods";
import WindowToast from "../../Utils/WindowToast";
import DropdownClearCaret from "../DropdownClearCaret";
import {
  FluentUIDecorator,
  FluentUIDecoratorTypes,
} from "../FluentUIDecorator/FluentUIDecorator";
import TargetList from "../TargetList/TargetList";
import { useLoaderContext } from "../../Context/LoaderContext";

const targetDataToTargetItem = (target: TargetData): ITargetItem => {
  return {
    id: target.target_cd,
    aliasName: target.trgt_nm,
    targetName: target.trgt_nm,
    targetAge: target.target_age,
    lithology: target.main_lithology_cd,
    md: -1,
    tvdss: -1,
    selected: target.drilled === "Y",
    userEdited: false,
    well_cd: target.well_cd,
  };
};

const ReservoirInformation = ({ mode }: { mode: FormMode }) => {
  const { formState, setFormState } = useContext(FormContext);

  const dispatch = useDispatch();

  const loader = useLoaderContext();

  const { trapTypeOptions, lithologyOptions } = useInputOptions();

  const hydrocarbonPhases = useSelector<GlobalState, HydrocarbonPhase[]>(
    (state) => state.form.hydrocarbonPhases
  );

  const ARISnameOptions = useSelector<GlobalState, IArisField[]>(
    (state) => state.form.arisFields
  );
  const xwareProspectOptions = useSelector<GlobalState, Prospect[]>(
    (state) => state.form.prospects
  );
  const reservoirFieldOptions = useSelector<
    GlobalState,
    CountryReservoirLevel[]
  >((state) => state.form.reservoirOptions);

  const [reservoirLevelOptions, setReservoirLevelOptions] = useState<
    IDropdownOption[]
  >([]);

  const phase = _.first(
    hydrocarbonPhases.filter(
      (x) => x.hydrocarbon_phase_cd === formState.hydrocarbonPhase
    )
  );
  const mainFluid = phase?.main_hydrocarbon_phase_desc;
  const fluidDetail = phase?.hydrocarbon_phase_detailed;

  let lithologies = formState.estimatedTargets
    .filter((x) => x.selected)
    .map((x) => getValueFromOptions(lithologyOptions, x?.lithology))
    .filter((x) => !!x);

  const lithology = [...new Set(lithologies)].join(", ");

  if (formState.lithology !== lithology)
    setFormState((prev) => ({
      ...prev,
      lithology,
    }));

  useEffect(() => {
    if (formState.reservoirField?.key)
      getReservoirLevel(formState.reservoirField?.key?.toString());
  }, []);

  // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
  const isReviewedEdit = mode === FormMode.ReviewedEdit;

  const notifications = formState.missingDataNotifications;

  const checkMissingField = (
    payload: string | number | any[],
    propertyName: keyof SpudLetterDto
  ) => {
    if (mode !== FormMode.Edit) return;
    if (typeof payload === "object" && payload.length !== 0) return;
    if (!!payload) return;
    let notification = notifications.filter((x) => x.field === propertyName);
    if (_.isEmpty(notification) || !_.first(notification).notificationSent) {
      dispatch(
        FormActions.setMissingDataDialog({
          show: true,
          fields: [propertyName],
        })
      );
    }
  };

  const getReservoirLevel = (resFieldId: string) => {
    loader.show();
    ApiService.InboundController.getReservoirLevel(
      resFieldId ?? formState.reservoirField?.key?.toString(),
      (response: IAPIResponse) => {
        loader.hide();
        if (response.error === null) {
          let reservoirLevels = response.payload as ReservoirLevel[];

          if (reservoirLevels.length === 0) {
            let notification = formState.missingDataNotifications.filter(
              (x) => x.field === "reservoirLevel"
            );
            if (
              _.isEmpty(notification) ||
              !_.first(notification).notificationSent
            ) {
              dispatch(
                FormActions.setMissingDataDialog({
                  show: true,
                  fields: ["reservoirLevel"],
                })
              );
            }
          }

          setReservoirLevelOptions(
            reservoirLevels.map((x) => ({
              key: x.reserv_level_id,
              text: x.reser_level_name,
            }))
          );
        } else {
          WindowToast.error("Error retrieving reservoir levels");
        }
      }
    );
  };

  const onChangeReservoirField = (option: IDropdownOption) => {
    setFormState((prev) => ({
      ...prev,
      reservoirField: option,
      reservoirLevel: [],
    }));
    getReservoirLevel(option.key.toString());
  };

  /**
   * To be called only on user input
   * @param arisField
   */
  const onChangeAris = (arisField: IDropdownOption) => {
    if (arisField === null) {
      setFormState((prev) => ({
        ...prev,
        arisField: null,
        prospect: null,
        estimatedTargets: [],
        hiip: "",
        pos: "",
        trapType: null,
        formationAge: "",
        lithology: "",
        hydrocarbonPhase: "",
      }));
      return;
    }

    setFormState((prev) => ({ ...prev, arisField: arisField }));

    if (formState.ownership && formState.ownership.key === "E") {
      loader.show();
      //Get data SPUD_LETTER_DATA_V based on aris
      ApiService.InboundController.getProspectData(
        formState.wellCode,
        arisField.key.toString(),
        undefined,
        (response: IAPIResponse) => {
          if (response.raw.status === 204 || response.payload == null) {
            // WindowToast.info('No prospect was associated with this aris field, please choose one');
            dispatch(
              FormActions.setMissingDataDialog({
                show: true,
                fields: ["estimatedTargets"],
              })
            );
            return;
          }
          let payload = response.payload as ProspectData;

          // checkMissingField(payload.prspct_id, 'prospect');
          checkMissingField(payload.targets, "estimatedTargets");
          if (payload.targets.length > 0) {
            checkMissingField(
              payload.targets.filter((x) => x.target_age_cd == null),
              "formationAge"
            );
            checkMissingField(
              payload.targets.filter((x) => x.main_lithology_cd == null),
              "lithology"
            );
          }
          checkMissingField(payload.hiip_prognosis, "hiip");
          checkMissingField(payload.pos_prognosis, "pos");
          checkMissingField(payload.trap_type_prspct_cd, "trapType");
          checkMissingField(payload.hc_phase, "hydrocarbonPhase");

          let hc_phase = _.first(
            hydrocarbonPhases.filter(
              (x) =>
                x.main_hydrocarbon_phase_desc ===
                BMT_PHASE_TO_XWARE_PHASE[payload.hc_phase]
            )
          );

          setFormState((prev) => ({
            ...prev,
            arisField: arisField,
            prospect: { name: payload.prspct_name, key: payload.prspct_id },
            estimatedTargets: payload.targets.map((x) =>
              targetDataToTargetItem(x)
            ),
            hiip: payload.hiip_prognosis.toFixed(0),
            pos: payload.pos_prognosis.toString(),
            trapType: {
              key: payload.trap_type_prspct_cd,
              text: payload.trap_type_prspct_desc,
            },
            hydrocarbonPhase: hc_phase.hydrocarbon_phase_cd,
          }));
        }
      ).finally(loader.hide);
    }

    loader.show();
    ApiService.InboundController.getCountryReservoirLevels(
      arisField.key.toString()
    )
      .then((response) => {
        if (response.error !== null || response.payload == null) {
          return;
        }
        let payload = response.payload as CountryReservoirLevel[];
        dispatch(FormActions.setReservoirOptions(payload));
        let uniqueReservoirFields = payload
          .map((x) => ({
            key: x.reserv_field_id,
            text: x.reservoir_field_name,
          }))
          .filter(
            (x, index, self) => index === self.findIndex((t) => t.key === x.key)
          );
        if (uniqueReservoirFields.length === 1) {
          const item = _.first(uniqueReservoirFields);
          setFormState((prev) => ({
            ...prev,
            reservoirField: item,
            reservoirLevel: [],
          }));
        } else {
          setFormState((prev) => ({
            ...prev,
            reservoirField: null,
            reservoirLevel: [],
          }));
        }
      })
      .finally(loader.hide);
  };

  /**
   * To be called only on user input
   * @param prospect
   */
  const onChangeProspect = (prospect: ITag | null) => {
    if (prospect === null) {
      setFormState((prev) => ({
        ...prev,
        prospect: null,
        estimatedTargets: [],
        hiip: "",
        pos: "",
        trapType: null,
        formationAge: "",
        lithology: "",
        hydrocarbonPhase: "",
      }));
      return;
    }

    setFormState((prev) => ({ ...prev, prospect: prospect }));

    if (formState.ownership.key !== "E") {
      return;
    }

    loader.show();
    //Get data SPUD_LETTER_DATA_V based on prospect
    ApiService.InboundController.getProspectData(
      formState.wellCode,
      undefined,
      prospect.key.toString()
    )
      .then((response: IAPIResponse) => {
        if (response.raw.status === 204 || response.payload === null) {
          dispatch(
            FormActions.setMissingDataDialog({
              show: true,
              fields: ["estimatedTargets"],
            })
          );
          // WindowToast.info('No targets were associated with the prospect');
          return;
        }
        let payload = response.payload as ProspectData;

        // checkMissingField(payload.prspct_id, 'prospect');
        checkMissingField(payload.targets, "estimatedTargets");
        if (payload.targets.length > 0) {
          checkMissingField(
            payload.targets.filter((x) => x.target_age_cd == null),
            "formationAge"
          );
          checkMissingField(
            payload.targets.filter((x) => x.main_lithology_cd == null),
            "lithology"
          );
        }
        checkMissingField(payload.hiip_prognosis, "hiip");
        checkMissingField(payload.pos_prognosis, "pos");
        checkMissingField(payload.trap_type_prspct_cd, "trapType");
        checkMissingField(payload.hc_phase, "hydrocarbonPhase");

        let hc_phase = _.first(
          hydrocarbonPhases.filter(
            (x) =>
              x.main_hydrocarbon_phase_desc ===
              BMT_PHASE_TO_XWARE_PHASE[payload.hc_phase]
          )
        );

        //assign targets and the rest
        setFormState((prev) => ({
          ...prev,
          prospect: prospect,
          estimatedTargets: payload.targets.map((x) =>
            targetDataToTargetItem(x)
          ),
          hiip: payload.hiip_prognosis.toFixed(0),
          pos: payload.pos_prognosis.toString(),
          trapType: {
            key: payload.trap_type_prspct_cd,
            text: payload.trap_type_prspct_desc,
          },
          hydrocarbonPhase: hc_phase.hydrocarbon_phase_cd,
        }));
      })
      .catch(WindowToast.error)
      .finally(loader.hide);
  };

  const onChangeHydrocarbonPhase = (
    mainFluid?: string,
    fluidDetail?: string
  ) => {
    if (!!mainFluid) {
      setFormState((prev) => ({
        ...prev,
        hydrocarbonPhase: _.first(
          hydrocarbonPhases.filter(
            (x) => x.main_hydrocarbon_phase_desc === mainFluid
          )
        ).hydrocarbon_phase_cd,
      }));
    } else if (fluidDetail) {
      setFormState((prev) => ({
        ...prev,
        hydrocarbonPhase: _.first(
          hydrocarbonPhases.filter(
            (x) => x.hydrocarbon_phase_detailed === fluidDetail
          )
        ).hydrocarbon_phase_cd,
      }));
    }
  };

  useEffect(() => {
    ApiService.InputOptionsController.getFormationAge(
      formState.estimatedTargets
        .filter((x) => x.selected)
        .map((x) => x.targetAge)
    ).then((res) => {
      let formationAge = res.payload as string;
      setFormState((prev) => ({
        ...prev,
        formationAge,
      }));
    });
    // .catch(WindowToast.error);
  }, [formState.estimatedTargets]);

  let resLevelPlaceholder = "Not available";
  if (formState.reservoirField) {
    resLevelPlaceholder =
      reservoirLevelOptions.length > 0
        ? "Select"
        : "No level available for this Field";
  }

  return (
    <div className="form-summary-form-container">
      <div className="flex-row">
        <span className="form-section-title">
          {FormSections.RESERVOIR_INFO.title}
        </span>
      </div>

      <div className="field-row">
        <div className="field-50 ">
          <FluentUIDecorator
            label="Aris Field name"
            info={getInputInfoValue("reservoir-info", "aris-field")}
            required={false}
            fluentComponent={FluentUIDecoratorTypes.Dropdown({
              disabled: isReviewedEdit,
              placeholder: "Select",
              options: ARISnameOptions.map(
                (x) =>
                  ({
                    key: x.aris_field_id,
                    text: x.field_desc,
                  } as IDropdownOption)
              ),
              selectedKey: formState.arisField?.key,
              onChange: (event, option) => onChangeAris(option),
              onRenderCaretDown: () => (
                <DropdownClearCaret
                  condition={formState.arisField !== null}
                  onClear={() =>
                    setFormState((prev) => ({ ...prev, arisField: null }))
                  }
                />
              ),
            })}
          />
        </div>
        <div className="field-50 ">
          <FluentUIDecorator
            label="Prospect"
            info={getInputInfoValue("reservoir-info", "prospect")}
            required={false}
            fluentComponent={FluentUIDecoratorTypes.TagPicker({
              disabled: isReviewedEdit,
              pickerSuggestionsProps: {
                suggestionsHeaderText: "Suggested prospects",
                noResultsFoundText: "No prospects found",
              },
              onResolveSuggestions: (filterText: string, tagList: ITag[]) => {
                if (!filterText) return [];
                return xwareProspectOptions //not ProspectData
                  .map((x) => ({ key: x.prsp_id, name: x.prsp_name }))
                  .filter((tag) => {
                    return (
                      tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >
                      -1
                    );
                  });
              },
              onItemSelected: (selectItem: ITag) => {
                onChangeProspect(selectItem);
                return selectItem;
              },
              onChange: (items: ITag[]) => {
                if (items.length === 0) {
                  onChangeProspect(null);
                }
              },
              itemLimit: 1,
              defaultSelectedItems: formState.prospect
                ? [formState.prospect]
                : [],
            })}
          />
        </div>
      </div>

      <hr style={{ marginTop: "2em", marginBottom: "2rem" }} />

      {formState.ownership?.key === "E" && (
        <>
          <span className="form-section-title">
            {FormSections.ESTIMATED_TARGET.title}
          </span>

          <div className="form-summary-form-container">
            <TargetList
              estimatedTargets={formState.estimatedTargets}
              setState={setFormState}
              mode={mode}
            />

            <hr style={{ marginTop: "2em", marginBottom: "2rem" }} />

            <span className="form-section-title">Exploration Only</span>

            <div className="field-row">
              <div className="field-33 ">
                <FluentUIDecorator
                  label="HIIP [MBOE]"
                  info={getInputInfoValue("reservoir-info", "hiip")}
                  required
                  errorMessage={
                    (window as any)["highlight-errors"] === 1
                      ? "This field is required"
                      : undefined
                  }
                  fluentComponent={FluentUIDecoratorTypes.TextField({
                    disabled: isReviewedEdit,
                    type: "number",
                    placeholder: "e.g.: 642",
                    value: formState.hiip,
                    onChange: (event, text) =>
                      setFormState((prev) => ({ ...prev, hiip: text })),
                  })}
                />
              </div>
              <div className="field-33 ">
                <FluentUIDecorator
                  label="POS [%]"
                  info={getInputInfoValue("reservoir-info", "pos")}
                  required
                  errorMessage={
                    (window as any)["highlight-errors"] === 1
                      ? "This field is required"
                      : undefined
                  }
                  fluentComponent={FluentUIDecoratorTypes.TextField({
                    disabled: isReviewedEdit,
                    type: "number",
                    placeholder: "e.g.: 55",
                    value: formState.pos,
                    onChange: (event, text) =>
                      setFormState((prev) => ({ ...prev, pos: text })),
                  })}
                />
              </div>
              <div className="field-33 ">
                <FluentUIDecorator
                  label="Trap Type"
                  info={getInputInfoValue("reservoir-info", "trap-type")}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    disabled: isReviewedEdit,
                    placeholder: "Select",
                    options: trapTypeOptions,
                    selectedKey: formState.trapType?.key,
                    onChange: (event, option) =>
                      setFormState((prev) => ({ ...prev, trapType: option })),
                    onRenderCaretDown: () => (
                      <DropdownClearCaret
                        condition={formState.trapType !== null}
                        onClear={() =>
                          setFormState((prev) => ({ ...prev, trapType: null }))
                        }
                      />
                    ),
                  })}
                />
              </div>
            </div>

            <div className="field-row">
              <div className="field-50 ">
                <FluentUIDecorator
                  label="Formation Age"
                  info={getInputInfoValue("reservoir-info", "formation-age")}
                  fluentComponent={FluentUIDecoratorTypes.TextField({
                    placeholder: "Not available",
                    value: formState.formationAge || "",
                    disabled: true,
                  })}
                />
              </div>
              <div className="field-50 ">
                <FluentUIDecorator
                  label="Lithology"
                  info={getInputInfoValue("reservoir-info", "lithology")}
                  fluentComponent={FluentUIDecoratorTypes.TextField({
                    placeholder: "Not available",
                    value: formState.lithology,
                    disabled: true,
                  })}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {true && (
        <div className="field-row">
          <div className="field-50 ">
            <FluentUIDecorator
              label="Reservoir Field"
              info={getInputInfoValue("reservoir-info", "reservoir-field")}
              required={["C", "O"].includes(formState.ownership?.key)}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                disabled: isReviewedEdit,
                placeholder: "Select",
                options: reservoirFieldOptions
                  .map((x) => ({
                    key: x.reserv_field_id,
                    text: x.reservoir_field_name,
                  }))
                  .filter(
                    (x, index, self) =>
                      index === self.findIndex((t) => t.key === x.key)
                  ),
                selectedKey: formState.reservoirField?.key,
                onChange: (event, option) => onChangeReservoirField(option),
                onRenderCaretDown: () => (
                  <DropdownClearCaret
                    condition={formState.reservoirField !== null}
                    onClear={() =>
                      setFormState((prev) => ({
                        ...prev,
                        reservoirField: null,
                        reservoirLevel: [],
                      }))
                    }
                  />
                ),
              })}
            />
          </div>
          <div className="field-50 ">
            <FluentUIDecorator
              label="Reservoir Level(s)"
              info={getInputInfoValue("reservoir-info", "reservoir-level")}
              required={["C", "O"].includes(formState.ownership?.key)}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                disabled: isReviewedEdit,
                placeholder: resLevelPlaceholder,
                options: reservoirFieldOptions
                  .filter(
                    (x) => x.reserv_field_id === formState.reservoirField?.key
                  )
                  .map((x) => ({
                    key: x.reserv_level_id,
                    text: x.reserv_level_name,
                  })),
                selectedKeys: formState.reservoirLevel.map((x) =>
                  x.key.toString()
                ),
                multiSelect: true,
                onChange: (event, option) => {
                  if (option) {
                    setFormState((prev) => ({
                      ...prev,
                      reservoirLevel: option.selected
                        ? [...prev.reservoirLevel, option]
                        : prev.reservoirLevel.filter(
                            (x) => x.key !== option.key
                          ),
                    }));
                  }
                },
              })}
            />
          </div>
        </div>
      )}

      <div className="field-row">
        <div className="field-50 ">
          <FluentUIDecorator
            label="Estimated main fluid"
            info={getInputInfoValue("reservoir-info", "main-fluid")}
            fluentComponent={FluentUIDecoratorTypes.Dropdown({
              disabled: isReviewedEdit,
              placeholder: "Select",
              options: _.uniq(
                hydrocarbonPhases,
                false,
                (p) => p.main_hydrocarbon_phase_desc
              ).map((x) => ({
                key: x.main_hydrocarbon_phase_desc,
                text: capitalize(x.main_hydrocarbon_phase_desc),
              })),
              selectedKey: mainFluid,
              onChange: (event, option) =>
                onChangeHydrocarbonPhase(option.key.toString(), undefined),
            })}
          />
        </div>
        <div className="field-50 ">
          <FluentUIDecorator
            label="Fluid details"
            info={getInputInfoValue("reservoir-info", "fluid-details")}
            fluentComponent={FluentUIDecoratorTypes.Dropdown({
              disabled: isReviewedEdit || !mainFluid,
              placeholder: "Select",
              options: hydrocarbonPhases
                .filter((x) => x.main_hydrocarbon_phase_desc === mainFluid)
                .map((x) => ({
                  key: x.hydrocarbon_phase_detailed,
                  text: capitalize(x.hydrocarbon_phase_detailed),
                })),
              selectedKey: fluidDetail,
              onChange: (event, option) =>
                onChangeHydrocarbonPhase(undefined, option.key.toString()),
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default ReservoirInformation;
