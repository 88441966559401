import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { IChoiceGroupOption, IDropdownOption, ITag } from "@fluentui/react";

import { KeyValuePair } from "./ApiTypes";
import { FileInfo } from "./FileUtils";
import { FormData, IGeneralInfoState, INotesState, IReservoirInfoState, IWellInfoState } from "./FormRoutesStates";
import { ITargetItem } from "./ITargetItem";
import { Country } from "./IUser";

dayjs.extend(utc);

export interface InboundSpudLetterDto {
    spudLetterId: string;
    tight: boolean;
    locked: boolean;

    place: string;
    date?: Date;
    from: string;
    wellCode: string;
    wellName: string;
    country: Country;
    blockName: string;
    operator: string;
    participatingInterest: number;
    workingInterest: number;
    referenceSystem: string;
    ownership: string;
    eniOperated: boolean;
    onshoreOffshore: string;

    arisField: KeyValuePair;
    prospect: KeyValuePair;

    estimatedTargets: ITargetItem[];

    hiip: number;
    pos: number;
    trapType: string;
    formationAge: string;
    lithology: string;

    reservoirField: KeyValuePair;
    reservoirLevel: KeyValuePair[];
    hydrocarbonPhase: string;

    spudDate?: Date;
    rig: string;
    rigContractor: string;
    productionType: string;
    path: string;
    waterDepth: number;
    rotaryTable: number;
    coordinates: { longitude: string; latitude: string };
    coordinateReferenceSystem: KeyValuePair;
    coordinateAdditionalInfo: string;
    plannedTVDSS: number;
    plannedMD: number;
    afeCostType: string;
    afeCostValue: number;
    afeDays: number;
    wcei: string;

    notes: string;

    attachments: FileInfo[];
}

export interface AllStates extends IGeneralInfoState, IReservoirInfoState, IWellInfoState, INotesState {
    id: string;
    tight: boolean;
    locked: boolean;
}
//generalState: IGeneralInfoState, reservoirState: IReservoirInfoState, wellState: IWellInfoState, notesState: INotesState
export const stateToDto = (state: FormData, attachments: FileInfo[]): InboundSpudLetterDto => {
    return {
        ...state,
        tight: state.userTight,
        locked: state.locked ?? false,
        referenceSystem: state.referenceSystem?.key,
        ownership: state.ownership?.key,
        eniOperated: state.eniOperated,
        onshoreOffshore: state.onshoreOffshore?.key,
        arisField: toKeyValuePair(state.arisField),
        prospect: toKeyValuePair(state.prospect),
        reservoirField: toKeyValuePair(state.reservoirField),
        reservoirLevel: state.reservoirLevel.length > 0 ? state.reservoirLevel.map((x) => toKeyValuePair(x)) : [],
        coordinates: {
            latitude: state.latitude
                ? state.latDirection === "N"
                    ? state.latitude
                    : (-state.latitude).toString()
                : "0",
            longitude: state.longitude
                ? state.lonDirection === "E"
                    ? state.longitude
                    : (-state.longitude).toString()
                : "0",
        },
        coordinateReferenceSystem: toKeyValuePair(state.coordinateReferenceSystem),
        afeCostType: state.afeCostType?.key ? state.afeCostType?.key.toString() : undefined,
        wcei: state.wcei?.key?.toString(),
        productionType: state.productionType?.key?.toString(),
        path: state.path?.key?.toString(),
        trapType: state.trapType?.key?.toString(),
        plannedTVDSS: Number.parseFloat(state.plannedTVDSS),
        plannedMD: Number.parseFloat(state.plannedMD),
        hiip: Number.parseFloat(state.hiip),
        pos: Number.parseFloat(state.pos),
        waterDepth: Number.parseFloat(state.waterDepth),
        rotaryTable: Number.parseFloat(state.rotaryTable),
        afeCostValue: Number.parseFloat(state.afeCostValue),
        afeDays: Number.parseFloat(state.afeDays),
        spudDate: dayjs(state.spudDate).utcOffset(0, true).toDate(),
        date: dayjs(state.date).utcOffset(0, true).toDate(),
        country: { countryCode: state.country.key.toString(), countryDescription: state.country.name.toString() },

        participatingInterest: !state.participatingInterest ? 0 : parseFloat(state.participatingInterest),
        workingInterest: !state.workingInterest ? 0 : parseFloat(state.workingInterest),

        attachments
    };
};

export const toKeyValuePair = (item: IChoiceGroupOption | IDropdownOption | ITag): KeyValuePair => {
    if (!item || !item.key) return null;
    if ("name" in item) {
        return { key: item.key?.toString(), value: item.name };
    }
    if ("text" in item) {
        return { key: item.key?.toString(), value: item.text };
    }
};

export const fromKeyValuePair = (item: KeyValuePair) => {
    if (!item) return null;
    return { key: item.key?.toString(), name: item.value, text: item.value };
};
