import { EDIT_LOCK_URLS } from "../../Constants/ApiUrls";
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";

const EditLockController = {
    lock: async (wellCode: string, callback?: (response: IAPIResponse) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = EDIT_LOCK_URLS.LOCK.replace("{wellCode}", wellCode);
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    unlock: async (wellCode: string, callback?: (response: IAPIResponse) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = EDIT_LOCK_URLS.UNLOCK.replace("{wellCode}", wellCode);
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getLock: async (wellCode: string, callback?: (response: IAPIResponse) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = EDIT_LOCK_URLS.GET_LOCK.replace("{wellCode}", wellCode);
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getActiveLocks: async (callback?: (response: IAPIResponse) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = EDIT_LOCK_URLS.GET_ACTIVE_LOCKS;
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default EditLockController;
