import { useState } from 'react';
import _ from 'underscore';

import { IDropdownOption } from '@fluentui/react';

import { ITargetItem } from '../../Models/ITargetItem';
import { Option } from '../../Models/Options';
import { getInputInfoValue } from '../../Reducers/Generic/GenericAction';
import { useBoolean, useInputOptions } from '../../Utils/Hooks';
import { getItemFromDropdownOptions, isNullOrEmpty } from '../../Utils/Methods';
import { FluentUIDecorator, FluentUIDecoratorTypes } from '../FluentUIDecorator/FluentUIDecorator';
import ModalDialog from '../ModalDialog/ModalDialog';
import { CommonModalButtons } from '../../Utils/ModalUtils';

export interface TargetFormModalProps {
  show?: boolean;
  onAbort?: () => void;
  onAccept?: (item: ITargetItem, isUpdate: boolean) => void;
  onDelete?: (item: ITargetItem) => void;
  data?: ITargetItem;
  targetAgeOptions: Option[];
  lithologyOptions: Option[];
}

const TargetFormModal = (props: TargetFormModalProps) => {
  const { data, targetAgeOptions, lithologyOptions } = props;
  const isUpdate = !!props.data;

  const [targetName, setTargetName] = useState<string>(data?.targetName ?? '');
  const [aliasName, setAliasName] = useState<string>(data?.aliasName ?? '');
  const [targetAge, setTargetAge] = useState<IDropdownOption>(
    getItemFromDropdownOptions(targetAgeOptions, data?.targetAge)
  );
  const [lithology, setLithology] = useState<IDropdownOption>(
    getItemFromDropdownOptions(lithologyOptions, data?.lithology)
  );
  const [tvdss, setTvdss] = useState<string>(data?.tvdss && data.tvdss !== -1 ? data.tvdss.toString() : '');
  const [md, setMd] = useState<string>(data?.md && data.md !== -1 ? data.md.toString() : '');

  const [highlighErrors, { setTrue: showErrors }] = useBoolean(false);

  const innerComponent = (
    <div>
      <FluentUIDecorator
        label='Target Name'
        info={getInputInfoValue('target-modal', 'target-name')}
        required
        errorMessage={highlighErrors ? 'This field is required' : undefined}
        fluentComponent={FluentUIDecoratorTypes.TextField({
          disabled: true,
          placeholder: 'Select',
          value: targetName,
          onChange: (event, text) => setTargetName(text),
        })}
      />
      <FluentUIDecorator
        label='Target Alias'
        info={getInputInfoValue('target-modal', 'target-alias')}
        required
        errorMessage={highlighErrors ? 'This field is required' : undefined}
        fluentComponent={FluentUIDecoratorTypes.TextField({
          placeholder: 'Select',
          value: aliasName,
          onChange: (event, text) => setAliasName(text),
        })}
      />
      <FluentUIDecorator
        label='Target Age'
        info={getInputInfoValue('target-modal', 'target-age')}
        required
        errorMessage={highlighErrors ? 'This field is required' : undefined}
        fluentComponent={FluentUIDecoratorTypes.Dropdown({
          placeholder: 'Select',
          options: targetAgeOptions,
          selectedKey: targetAge?.key,
          onChange: (event, option) => setTargetAge(option),
        })}
      />
      <FluentUIDecorator
        label='Lithology'
        info={getInputInfoValue('target-modal', 'lithology')}
        required
        errorMessage={highlighErrors ? 'This field is required' : undefined}
        fluentComponent={FluentUIDecoratorTypes.Dropdown({
          placeholder: 'Select',
          options: lithologyOptions,
          selectedKey: lithology?.key,
          onChange: (event, option) => setLithology(option),
        })}
      />
      <div className='field-row'>
        <div className='field-50'>
          <FluentUIDecorator
            label='TVDSS'
            info={getInputInfoValue('target-modal', 'tvdss')}
            required
            errorMessage={highlighErrors ? 'This field is required' : undefined}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              type: 'number',
              placeholder: 'Input number',
              value: tvdss,
              onChange: (event, number) => setTvdss(number),
            })}
          />
        </div>
        <div className='field-50'>
          <FluentUIDecorator
            label='MD'
            info={getInputInfoValue('target-modal', 'md')}
            required
            errorMessage={highlighErrors ? 'This field is required' : undefined}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              type: 'number',
              placeholder: 'Input number',
              value: md,
              onChange: (event, number) => setMd(number),
            })}
          />
        </div>
      </div>
    </div>
  );

  const confirm = () => {
    let valid = true;
    valid &&= !isNullOrEmpty(targetName);
    valid &&= !isNullOrEmpty(aliasName);
    valid &&= !!targetAge;
    valid &&= !!lithology;
    valid &&= !isNullOrEmpty(tvdss);
    valid &&= !isNullOrEmpty(md);

    if (!valid) {
      showErrors();
      return;
    }

    props.onAccept(
      {
        id: props.data?.id ?? _.uniqueId(),
        selected: props.data?.selected ?? false,
        userEdited: props.data?.userEdited ?? true,
        targetName,
        aliasName,
        targetAge: targetAge?.key?.toString(),
        lithology: lithology?.key?.toString(),
        tvdss: +tvdss,
        md: +md,
        well_cd: props.data?.well_cd,
      },
      isUpdate
    );
  };

  const cancel = () => props.onAbort();
  const onDelete = () => props.onDelete(data);

  let modalButtons = data?.userEdited
    ? CommonModalButtons.deleteCancelSave(onDelete, cancel, confirm)
    : CommonModalButtons.confirmCancelButtons(confirm, cancel);

  return (
    <ModalDialog
      modalTitle={isUpdate ? 'Edit entry' : 'Add new entry'}
      modalInnerComponent={innerComponent}
      enableModal={props.show}
      modalButtons={modalButtons}
      onAbort={cancel}
    />
  );
};

export default TargetFormModal;
