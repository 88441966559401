import { useDispatch, useSelector } from "react-redux";

import { AttachmentContainer, ErrorType } from "../../Components/AttachmentContainer/AttachmentContainer";
import FileUploadBoxPreview from "../../Components/FileUploadBoxPreview/FileUploadBoxPreview";
import { DOCUMENT_URLS } from "../../Constants/ApiUrls";
import { useLoaderContext } from "../../Context/LoaderContext";
import { FileInfo, FileUtils } from "../../Models/FileUtils";
import { FormActions } from "../../Reducers/Form/FormActions";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { APISettings } from "../../Services/Internal/AjaxService";
import { FormMode } from "../../Utils/FormMode";
import WindowToast from "../../Utils/WindowToast";

interface FormSummaryDragAndDropProps {
    wellCode: string;
    mode: FormMode;
}

const FormSummaryDragAndDrop = (props: FormSummaryDragAndDropProps) => {
    const { wellCode } = props;

    const attachments = useSelector<GlobalState, FileInfo[]>((state) => state.form.attachments);
    const loader = useLoaderContext();
    const dispatch = useDispatch();

    const onAttachmentDelete = (wellCode: string, fileId: string) => {
        if (attachments?.length > 0) {
            loader.show();
            ApiService.DocumentController.deleteAttachment(wellCode, fileId)
                .then((response) => {
                    if (response.error !== null) throw response.error;
                    dispatch(FormActions.deleteAttachment(fileId));
                })
                .catch(WindowToast.error)
                .finally(loader.hide);
        }
    };

    const onAddAttachments = async (files: File[]) => {
        loader.show();
        let errorType: ErrorType = "";
        await ApiService.DocumentController.uploadAttachments(props.wellCode, files)
            .then((response) => {
                if (response.error !== null) {
                    if (response.payload.status === 400 && response.payload.title === "Invalid File Type") {
                        errorType = "not-supported-format";
                    } else {
                        errorType = "generic";
                    }
                } else {
                    dispatch(FormActions.addAttachments(response.payload || []));
                }
            })
            .catch(WindowToast.error)
            .finally(loader.hide);

        return errorType;
    };

    return (
        <div style={{ padding: "1em" }}>
            <div style={{ margin: "2em 0 1em 0" }}>Attachments</div>
            {props.mode === FormMode.Edit ? (
                <AttachmentContainer
                    wellCode={wellCode}
                    preloadFiles={attachments}
                    disabled={props.mode !== FormMode.Edit}
                    onAddAttachments={onAddAttachments}
                    onAttachmentDelete={(fileId) => {
                        onAttachmentDelete(wellCode, fileId);
                    }}
                />
            ) : attachments.length > 0 ? (
                attachments.map((x, i) => (
                    <div style={{ margin: "1em" }} key={i}>
                        <FileUploadBoxPreview
                            fileId={x.id}
                            fileName={x.fileName}
                            fileSize={x.size}
                            lastUpdated={x.uploadTime}
                            onClick={() => {
                                FileUtils.downloadFromUrl(
                                    `${APISettings.baseUrl}${DOCUMENT_URLS.ATTACHMENT.replace(
                                        "{wellCode}",
                                        wellCode
                                    ).replace("{attachmentId}", x.id)}`,
                                    x.fileName
                                );
                            }}
                        />
                    </div>
                ))
            ) : (
                <div style={{ fontWeight: 500, margin: "1em" }}>No attachments found</div>
            )}
        </div>
    );
};

export default FormSummaryDragAndDrop;
