export enum UserRole {
  Reader,
  Writer,
  Approver,
  Admin
}

export const userRoleNameMap = [
  { key: UserRole.Reader, name: 'Reader' },
  { key: UserRole.Writer, name: 'Writer' },
  { key: UserRole.Approver, name: 'Approver' },
  { key: UserRole.Admin, name: 'Admin' },
];
