import "./FileUploadBoxPreview.scss";

import { Icon } from "@fluentui/react";

export const FILE_ICONS: Array<any> = [
    { name: "accdb" },
    { name: "audio", validFor: ["mp3"] },
    { name: "code" },
    { name: "csv" },
    { name: "docx", validFor: ["doc"] },
    { name: "dotx", validFor: ["dot"] },
    { name: "mpp" },
    { name: "mpt" },
    { name: "model" },
    { name: "one" },
    { name: "onetoc" },
    { name: "potx", validFor: ["pot"] },
    { name: "ppsx", validFor: ["pps"] },
    { name: "pdf" },
    { name: "photo", validFor: ["jpg", "png", "bmp", "jpeg", "gif"] },
    { name: "pptx", validFor: ["ppt"] },
    { name: "presentation" },
    { name: "pub" },
    { name: "rtf" },
    { name: "spreadsheet" },
    { name: "txt" },
    { name: "xml" },
    { name: "vector" },
    { name: "zip", validFor: ["tar", "7z", "rar"] },
    { name: "vsdx", validFor: ["vsd"] },
    { name: "vssx", validFor: ["vss"] },
    { name: "vstx", validFor: ["vst"] },
    { name: "xlsx", validFor: ["xls"] },
    { name: "xltx", validFor: ["xlt"] },
    { name: "xsn" },
    {
        name: "video",
        validFor: ["wav", "mkv", "mp4", "mpeg", "avi", "mov", "webm", "ogg", "m4v", "mpg", "wmv"],
    },
    { name: "folder" },
];

export interface IFilePreviewDescriptor {
    fileId: string;
    fileName: string;
    fileSize: number;
    extraDesc?: string;
    lastUpdated: string;
    showLargeBox?: boolean;
    onExitClick?: (event: any) => void;
    readOnly?: boolean;
    extraIcon?: string | null;
    onClick?: () => void;
}

export const formatFileSize = (size: number) => {
    if (!size) {
        return "";
    }

    let label = "";
    if (size > 1000000000000) {
        label = (size / 1000000000000).toString().substring(0, 6) + "TB";
    } else if (size > 1000000000) {
        label = (size / 1000000000).toString().substring(0, 6) + "GB";
    } else if (size > 1000000) {
        label = (size / 1000000).toString().substring(0, 6) + "MB";
    } else if (size > 1000) {
        label = (size / 1000).toString().substring(0, 6) + "KB";
    } else {
        label = size.toString().substring(0, 6) + "Bytes";
    }

    return label;
};

const processFormat = (fileName: string) => {
    if (!fileName) {
        return "";
    }

    let nameSpl = fileName.split(".");
    return nameSpl[nameSpl.length - 1].toUpperCase();
};

export const getUploadIconFromExt = (fileFormat: string) => {
    for (let i = 0; i < FILE_ICONS.length; i++) {
        let icon = FILE_ICONS[i];
        if (icon.name === fileFormat) {
            return (
                <img
                    className="upload-preview-icon"
                    src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${icon.name}.svg`}
                    alt=""
                />
            );
        }
        if (icon.validFor) {
            if (icon.validFor.indexOf(fileFormat) !== -1) {
                return (
                    <img
                        className="upload-preview-icon"
                        src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${icon.name}.svg`}
                        alt=""
                    />
                );
            }
        }
    }

    return <Icon iconName={"Page"} />;
};

export const getUploadIcon = (fileName: string) => {
    let format = processFormat(fileName).toLocaleLowerCase();
    return getUploadIconFromExt(format);
};

const FileUploadBoxPreview = (props: IFilePreviewDescriptor) => {
    const onDelete = () => {
        if (props.onExitClick) {
            if (props.fileId) {
                props.onExitClick(props.fileId);
            }
        }
    };

    return (
        <div className="file-upload-box-main-wrap">
            <div className="file-upload-box-upper-zone">
                <div className="file-upload-box-ico-name-wrap" onClick={props.onClick}>
                    {props.extraIcon && (
                        <div className="file-upload-box-ico-extra">
                            <div className="file-upload-box-ico-extra-inner">
                                <Icon iconName={props.extraIcon} />
                            </div>
                        </div>
                    )}
                    <div className="file-upload-box-icon">{getUploadIcon(props.fileName)}</div>
                    <div className="file-upload-box-inner-wrap">
                        <div className="file-upload-box-name">{props.fileName}</div>
                        <div className="file-upload-box-small-file-size">{formatFileSize(props.fileSize)}</div>
                        <div className="file-upload-box-small-file-size">{props.extraDesc}</div>
                    </div>
                </div>
                {props.readOnly !== true && (
                    <div className="file-upload-box-exit-button" onClick={onDelete}>
                        X
                    </div>
                )}
            </div>
            {props.showLargeBox === true && (
                <div className="file-upload-box-lower-zone">
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>File Format</th>
                                <th>File Size</th>
                                <th>Last Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{processFormat(props.fileName)}</td>
                                <td>{formatFileSize(props.fileSize)}</td>
                                <td>{new Date(props.lastUpdated).toLocaleDateString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default FileUploadBoxPreview;
