import './ActivityColumn.scss';

import moment from 'moment';
import { useEffect, useState } from 'react';

import { FontIcon } from '@fluentui/react';

export const capitalizeText = (text: string) => {
  if (!text || text === "") {
    return text;
  }
  return text.charAt(0).toLocaleUpperCase() + text.substring(1);
};

/**
 * Enum on backend
 *         
 *  SpudLetterCreated,
    SpudLetterEdited, 
    SpudLetterDeleted,
    SpudLetterSentForReview,
    SpudLetterSentForApproval,
    SpudLetterApproved,
 * 
 * 
 */

export const operationTypes = [
  { name: 'SpudLetterCreated', icon: 'Add', message: 'created' },
  { name: 'SpudLetterEdited', icon: 'Edit', message: 'edited' },
  { name: 'SpudLetterDeleted', icon: 'Delete', message: 'deleted' },
  { name: 'SpudLetterSentForReview', icon: 'Send', message: 'sent for review' },
  { name: 'SpudLetterSentForApproval', icon: 'CheckMark', message: 'sent for approval' },
  { name: 'SpudLetterApproved', icon: 'ChromeClose', message: 'N/A' },
];

export interface IActivity {
  id: string;
  userId: string;
  date: string;
  referenceId: string;
  operationType: number;
  message: string;
  referenceType: number;
  userFullName: string;
}

const dateDiffInDays = (date: string, today: string) => {
  var start = moment(today);
  var end = moment(date);

  //Difference in number of days
  return Math.floor(moment.duration(start.diff(end)).asDays());
};

export interface IActivityBoxProps {
  message: string;
  timeSpan: string;
  date: string;
  operationType: number;
  renderedLink: JSX.Element;
  showUserName?: boolean;
  hideAction?: boolean;
}

const ActivityBox = (props: IActivityBoxProps) => {
  let spl: string[] = props.message.split(":");
  let action = spl.length > 1 ? spl[0] : null;
  let message = spl.length > 1 ? spl[1] : spl[0];

  return (
    <div className="activity-column-box">
      <div className="activity-column-box-icon">
        <FontIcon iconName={operationTypes[props.operationType].icon}/>
      </div>
      <div className="activity-column-box-text">
        <div
          className="activity-column-contain-text file-linkname"
          title={message}
        >
          {props.renderedLink} {props.showUserName && operationTypes[props.operationType].message}
        </div>
        <div className="activity-column-box-date">
          {props.hideAction ? `${capitalizeText(props.timeSpan)}` : `${action} ${capitalizeText(props.timeSpan)}`}
        </div>
      </div>
    </div>
  );
};

export interface IActivitySection {
  minorLimit: number;
  majorLimit: number;
  label: string;
  noDataLabel: string;
}

export interface IActivityColumn {
  title: string;
  onEndButtonClick?: () => void;
  activityList: IActivity[];
  sectionList?: IActivitySection[];
  onRenderLink: (reference: IActivity) => JSX.Element;
  showUserName?: boolean;
  hideAction?: boolean;
}

export const ActivityColumn = (props: IActivityColumn) => {
  const sortMyList = (list: IActivity[]) => {
    let sortedList = list
      ? list.sort((a: IActivity, b: IActivity) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      })
      : [];

    return sortedList;
  };

  useEffect(() => {
    setActivities(sortMyList(props.activityList));
  }, [props.activityList]);

  const [dateToday, setDateToday] = useState<string>(new Date().toISOString());
  const [activities, setActivities] = useState<IActivity[]>(
    sortMyList(props.activityList)
  );

  let sectionsList: IActivitySection[] = props.sectionList
    ? props.sectionList
    : [
      {
        minorLimit: -1,
        majorLimit: 1,
        label: "Today",
        noDataLabel: "There are no activities for the current day.",
      },
      {
        minorLimit: 0,
        majorLimit: 8,
        label: "Last week",
        noDataLabel: "There are no activities from the previous week.",
      },
    ];

  return (
    <div className="activity-column-wrapper">
      <div className="activity-column-title-wrap">
        <div className="activity-column-title">{props.title}</div>
        <div className="activity-column-icon"></div>
      </div>
      {sectionsList.map((x: IActivitySection, i: number) => {
        let matchingActivities: IActivity[] = activities.filter(
          (z: IActivity) =>
            dateDiffInDays(z.date, dateToday) > x.minorLimit &&
            dateDiffInDays(z.date, dateToday) < x.majorLimit
        );

        return (
          <div key={i}>
            <div className="activity-column-section-title">{x.label}</div>
            <div className="activity-column-section">
              {matchingActivities.map((y: IActivity, i: number) => {
                return (
                  <ActivityBox
                    renderedLink={props.onRenderLink(y)}
                    key={i}
                    message={y.message}
                    operationType={y.operationType}
                    date={y.date}
                    timeSpan={moment(y.date).fromNow()}
                    showUserName={props.showUserName}
                    hideAction={props.hideAction}
                  />
                );
              })}
              {matchingActivities.length === 0 && (
                <div className="activity-column-no-activity-label">
                  {x.noDataLabel}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ActivityColumn;
