import {
  GenericActionType,
  SET_INPUT_INFOS,
} from "./GenericAction";
import { DEFAULT_GENERIC_STATE, GenericState } from "./GenericState";


export const genericReducer = (
  state: GenericState = DEFAULT_GENERIC_STATE,
  action: GenericActionType
): GenericState => {
  switch (action.type) {
    case SET_INPUT_INFOS:
      return {
        ...state,
        inputInfos: action.payload,
      };
    default:
      return state;
  }
};
