import { Icon, IconButton } from "@fluentui/react";
import { ITargetItem } from "../../Models/ITargetItem";
import { getValueFromDropdownOptions } from "../../Utils/Methods";
import { useInputOptions } from "../../Utils/Hooks";
import { LabelButton } from "@Eni/docware-fe-master";
import React from "react";

export interface TargetListColumnsProps {
    onEditTarget: (target: ITargetItem) => void;
    // onDeleteTarget: (target: ITargetItem) => void;
    isDisabled: boolean;
}

export const TargetListColumns = (props: TargetListColumnsProps) => {
    const { targetAgeOptions, lithologyOptions } = useInputOptions();

    return [
        {
            key: "alias-name",
            name: "Target",
            fieldName: "aliasName",
            size: "large",
            isMultiline: true,
        },
        {
            key: "target-age",
            name: "Target Age",
            fieldName: "targetAge",
            size: "large",
            onRender: (item: ITargetItem) =>
                getValueFromDropdownOptions(
                    React.useMemo(
                        () => targetAgeOptions.map((x) => ({ key: x.text, text: x.text })),
                        [targetAgeOptions]
                    ),
                    item?.targetAge
                ),
            isMultiline: true,
        },
        {
            key: "lithology",
            name: "Lithology",
            fieldName: "lithology",
            size: "medium",
            onRender: (item: ITargetItem) => getValueFromDropdownOptions(lithologyOptions, item?.lithology),
            isMultiline: true,
        },
        {
            key: "tvdss",
            name: "TVDSS",
            fieldName: "tvdss",
            size: "7-digit",
            onRender: (item: ITargetItem) => (!!item.tvdss && item.tvdss !== -1 ? item.tvdss : ""),
        },
        {
            key: "md",
            name: "MD",
            fieldName: "md",
            size: "7-digit",
            onRender: (item: ITargetItem) => (!!item.md && item.md !== -1 ? item.md : ""),
        },
        !props.isDisabled && {
            key: "edit",
            name: "edit",
            iconName: "Edit",
            size: "small",
            onRender: (item: ITargetItem) => (
                <IconButton
                    title="Edit"
                    iconProps={{ iconName: "Edit" }}
                    allowDisabledFocus
                    onClick={() => props.onEditTarget(item)}
                />
            ),
        },
    ];
};
