import store from "../Store";
import { IBaseFile, NavbarState } from "./ToolbarState";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_SHOW_ASSURANCE_SELECTOR = "SET_SHOW_ASSURANCE_SELECTOR";
export const SET_NAVBAR_STATE = "SET_NAVBAR_STATE";
export const SET_SELECTED_FILES = "SET_SELECTED_FILES";
export const SET_SHOW_LOCKED_MODAL = 'SET_SHOW_LOCKED_MODAL';
export const SET_SHOW_DISTRIBUTION_MODAL = 'SET_SHOW_DISTRIBUTION_MODAL';
export const SET_CURRENT_FILE = 'SET_CURRENT_FILE';

interface SetNavbarStateAction {
  type: typeof SET_NAVBAR_STATE;
  payload: NavbarState;
}

interface SetSelectedFilesAction {
  type: typeof SET_SELECTED_FILES;
  payload: { navbarState: NavbarState, selectedFiles: IBaseFile[] };
}

interface SetCurrentFile {
  type: typeof SET_CURRENT_FILE;
  payload: IBaseFile;
}

interface SetShowLockedModalActionType {
  type: typeof SET_SHOW_LOCKED_MODAL;
  payload: boolean;
}

interface SetShowDistributionModalActionType {
  type: typeof SET_SHOW_DISTRIBUTION_MODAL;
  payload: boolean;
}

export type ToolbarActionType =
  | SetNavbarStateAction
  | SetSelectedFilesAction
  | SetShowLockedModalActionType
  | SetShowDistributionModalActionType
  | SetCurrentFile;

const setNavbarStateAction = (data: NavbarState): SetNavbarStateAction => {
  return { type: SET_NAVBAR_STATE, payload: data };
};

const setSelectedFilesAction = (data: { navbarState: NavbarState, selectedFiles: IBaseFile[] }): SetSelectedFilesAction => {
  return { type: SET_SELECTED_FILES, payload: data };
};

const setCurrentFileAction = (file: IBaseFile): SetCurrentFile => {
  return { type: SET_CURRENT_FILE, payload: file };
};

const setShowLockedModal = (show: boolean): SetShowLockedModalActionType => {
  return { type: SET_SHOW_LOCKED_MODAL, payload: show };
}

const setShowDistributionModal = (show: boolean): SetShowDistributionModalActionType => {
  return { type: SET_SHOW_DISTRIBUTION_MODAL, payload: show };
}

export const ToolbarActions = {
  setSelectedFilesAction,
  setNavbarStateAction,
  setShowLockedModal,
  setCurrentFileAction,
  setShowDistributionModal
};
