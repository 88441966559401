import { InputInfo } from "../../Models/Options";

export interface GenericState {
  inputInfos: InputInfo[];
}

export const DEFAULT_GENERIC_STATE: GenericState = {
  inputInfos: []

};
