import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'underscore';

import { useInputOptions } from '../../app/api/get-input-options';
import { FormData } from '../../../models/FormRoutesStates';
import { fromKeyValuePair } from '../../../models/InboundSpudLetterDto';
import { SpudLetterDto } from '../../../models/SpudLetterDto';
import { FormActions } from '../../../stores/form/form-actions';
import { getItemFromDropdownOptions, getValueFromChoiceGroup } from '../../../utils/Methods';
import { setAttachments } from './attachments-slice';

interface FormContextType {
    formState: FormData;
    setFormState: React.Dispatch<React.SetStateAction<FormData>>;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export const useFormContext = () => {
    return useContext(FormContext);
};

const FormContextProvider = ({ children, letter }: { children: React.ReactNode; letter: SpudLetterDto }) => {
    const dispatch = useDispatch();

    const {
        data: { referenceSystemOptions, ownershipOptions, onshoreOptions, pathOptions, currencyOptions },
    } = useInputOptions();

    useEffect(() => {
        let notifications = letter.missingDataNotifications;

        let missingFields: (keyof SpudLetterDto)[] = [];
        const checkMissingField = (letter: SpudLetterDto, property: keyof SpudLetterDto) => {
            if (letter[property] === null) {
                let notification = notifications.filter((x) => x.field === property);
                if (_.isEmpty(notification) || !_.first(notification)!.notificationSent) {
                    missingFields.push(property);
                }
            }
        };
        checkMissingField(letter, 'wellName');
        checkMissingField(letter, 'country');
        checkMissingField(letter, 'blockName');
        checkMissingField(letter, 'operator');
        checkMissingField(letter, 'participatingInterest');
        checkMissingField(letter, 'workingInterest');
        checkMissingField(letter, 'operator');
        checkMissingField(letter, 'partnerships');
        checkMissingField(letter, 'onshoreOffshore');
        checkMissingField(letter, 'ownership');
        checkMissingField(letter, 'wcei');
        checkMissingField(letter, 'eniOperated');
        checkMissingField(letter, 'path');

        if (missingFields.length > 0) {
            dispatch(
                FormActions.setMissingDataDialog({
                    show: true,
                    fields: missingFields,
                }),
            );
        }
    }, []);

    useEffect(() => {
        dispatch(setAttachments(letter.attachments));
    }, []);

    let dateOffset = new Date(letter.date ?? '').getTimezoneOffset() * 60000;
    let spudDateOffset = new Date(letter.spudDate ?? '').getTimezoneOffset() * 60000;

    const getInitData = (letter: SpudLetterDto): FormData => ({
        ...letter,
        creationDate: new Date(letter.creationDate),
        updateDate: new Date(letter.updateDate),
        date: letter.date ? new Date(new Date(letter.date).getTime() + dateOffset) : undefined,
        spudDate: letter.spudDate ? new Date(new Date(letter.spudDate).getTime() + spudDateOffset) : undefined,
        ownership: letter.ownership
            ? {
                  key: letter.ownership,
                  text: getValueFromChoiceGroup(ownershipOptions, letter.ownership),
              }
            : null,
        referenceSystem: letter.referenceSystem
            ? {
                  key: letter.referenceSystem,
                  text: getValueFromChoiceGroup(referenceSystemOptions, letter.referenceSystem),
              }
            : null,
        onshoreOffshore: letter.onshoreOffshore
            ? {
                  key: letter.onshoreOffshore,
                  text: getValueFromChoiceGroup(onshoreOptions, letter.onshoreOffshore),
              }
            : null,
        arisField: fromKeyValuePair(letter.arisField),
        prospect: fromKeyValuePair(letter.prospect),
        reservoirField: fromKeyValuePair(letter.reservoirField),
        reservoirLevel: letter.reservoirLevel.map((x) => fromKeyValuePair(x)),
        productionType: {
            key: letter.productionType,
            text: letter.productionType,
        },
        path: getItemFromDropdownOptions(pathOptions, letter.path),
        coordinateReferenceSystem: fromKeyValuePair(letter.coordinateReferenceSystem),
        // coordinateFormat: _.first(coordinateTypeOptions.filter((x) => x.key === "dms")),
        latitude: Math.abs(Number(letter.coordinates?.latitude ?? 0)).toString(),
        longitude: Math.abs(Number(letter.coordinates?.longitude ?? 0)).toString(),
        latDirection: +letter.coordinates?.latitude < 0 ? 'S' : 'N',
        lonDirection: +letter.coordinates?.longitude < 0 ? 'W' : 'E',
        afeCostType: getItemFromDropdownOptions(currencyOptions, letter.afeCostType),
        wcei: { key: letter.wcei, text: letter.wcei },
        trapType: { key: letter.trapType, text: letter.trapType },
        country: { key: letter.country.countryCode, name: letter.country.countryDescription },

        participatingInterest: letter.participatingInterest.toFixed(2),
        workingInterest: letter.workingInterest.toFixed(2),
    });

    const [formState, setFormState] = useState<FormData>(getInitData(letter));

    useEffect(() => {
        setFormState(getInitData(letter));
    }, [letter, letter.updateDate]);

    return <FormContext.Provider value={{ formState, setFormState }}>{children}</FormContext.Provider>;
};

export default FormContextProvider;
