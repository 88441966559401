import { AREA_URLS } from '../../Constants/ApiUrls';
import { Area } from '../../Models/Area';
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from '../Internal/AjaxService';

const AreaController = {
  getAreas: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = AREA_URLS.GET_AREAS;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  insertArea: async (area: Area, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = AREA_URLS.AREA;
    request.method = 'POST';
    request.payload = area;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteArea: async (areaId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = AREA_URLS.DELETE_AREA.replace("{areaId}", areaId);
    request.method = 'DELETE';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  updateArea: async (area: Area, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = AREA_URLS.AREA;
    request.method = 'PUT';
    request.payload = area;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default AreaController;
