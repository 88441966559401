import "./UserRoles.scss";

import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";

import { IPersonaProps } from "@fluentui/react";

import { FormSections } from "../../Constants/FormSections";
import { IUser, personaToUser, userToPersona, userToPersonas } from "../../Models/IUser";
import { WorkflowRole, WorkflowSigner, useSigners } from "../../Models/SpudLetterDto";
import { UserRole } from "../../Models/UserRole";
import { FormContext } from "../../Pages/FormLayout/FormContext";
import { GlobalState } from "../../Reducers/RootReducer";
import { FormMode } from "../../Utils/FormMode";
import { PeoplePicker } from "../PeoplePicker/PeoplePicker";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import { getInputInfoValue } from "../../Reducers/Generic/GenericAction";

const UserRoles = ({ mode }: { mode: FormMode }) => {
    const { formState, setFormState } = useContext(FormContext);

    const explorationManager = formState.signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManager);
    const explorationManagerDeputy = formState.signers.filter(
        (x) => x.workflowRole === WorkflowRole.ExplorationManagerDeputy
    );
    const areaManager = formState.signers.filter((x) => x.workflowRole === WorkflowRole.AreaManager);
    const areaManagerDeputy = formState.signers.filter((x) => x.workflowRole === WorkflowRole.AreaManagerDeputy);
    const projectManager = formState.signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManager);
    const projectManagerDeputy = formState.signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManagerDeputy);

    const users = useSelector<GlobalState, IUser[]>((state) => state.form.users);
    // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    const writers = useMemo(
        () =>
            userToPersonas(
                users.filter((x) => x.roles.includes(UserRole.Writer) && x.roles.includes(UserRole.Approver))
            ),
        [users]
    );
    const approvers = useMemo(() => userToPersonas(users.filter((x) => x.roles.includes(UserRole.Approver))), [users]);

    const filterWriters = async (filterText: string, currentPersonas: IPersonaProps[]) => {
        return writers
            .filter((x) => !formState.signers.some((s) => s.id === x.key))
            .filter((x) => x.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
    };

    const filterApprovers = async (filterText: string, currentPersonas: IPersonaProps[]) => {
        return approvers
            .filter((x) => !formState.signers.some((s) => s.id === x.key))
            .filter((x) => x.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
    };

    const setSigner = (user: IPersonaProps, role: WorkflowRole) => {
        setFormState((prev) => {
            if (!user) {
                //remove user
                return {
                    ...prev,
                    signers: prev.signers.filter((x) => x.workflowRole !== role),
                };
            }
            let signers = prev.signers;
            let newUser = { ...personaToUser(user), workflowRole: role } as WorkflowSigner;
            if (!signers.some((x) => x.workflowRole === role)) {
                // if not in users, add
                return {
                    ...prev,
                    signers: [...prev.signers, newUser],
                };
            }

            return {
                //substitute user
                ...prev,
                signers: prev.signers.map((x) => (x.workflowRole === role ? newUser : x)),
            };
        });
    };

    return (
        <div className="form-summary-form-container">
            <div className="flex-row">
                <span className="form-section-title">{FormSections.USER_ROLES.title}</span>
            </div>

            {(formState.ownership?.key === "C" || formState.ownership?.key === "O") && (
                <React.Fragment>
                    <span className="form-section-title">Project Manager</span>
                    <div className="field-row">
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Manager*"
                                selectedUsers={userToPersonas(projectManager)}
                                onFilterChanged={function (
                                    filterText: string,
                                    currentPersonas: IPersonaProps[],
                                    limitResults?: number
                                ): IPersonaProps[] | Promise<IPersonaProps[]> {
                                    return filterApprovers(filterText, currentPersonas);
                                }}
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.ProjectManager);
                                }}
                                itemLimit={1}
                            />
                        </div>
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Deputy"
                                selectedUsers={userToPersonas(projectManagerDeputy)}
                                disabled={isReviewedEdit}
                                onFilterChanged={function (
                                    filterText: string,
                                    currentPersonas: IPersonaProps[],
                                    limitResults?: number
                                ): IPersonaProps[] | Promise<IPersonaProps[]> {
                                    return filterApprovers(filterText, currentPersonas);
                                }}
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.ProjectManagerDeputy);
                                }}
                                itemLimit={1}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}

            {formState.ownership?.key === "E" && (
                <React.Fragment>
                    <span className="form-section-title">Exploration Manager</span>

                    <div className="field-row" style={{ alignItems: "end" }}>
                        <div className="field-50 people-picker">
                            <FluentUIDecorator
                                required
                                label={"Manager"}
                                info={getInputInfoValue("user-roles", "exp-manager")}
                                fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                                    selectedUsers: userToPersonas(explorationManager),
                                    itemLimit: 1,
                                    disabled: isReviewedEdit,
                                    onPeopleChanged: (updatedPeople: IPersonaProps[]) => {
                                        let user = _.first(updatedPeople) as IPersonaProps;
                                        setSigner(user, WorkflowRole.ExplorationManager);
                                    },
                                    onFilterChanged: (
                                        filterText: string,
                                        currentPersonas: IPersonaProps[],
                                        limitResults?: number
                                    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
                                        return filterWriters(filterText, currentPersonas);
                                    },
                                })}
                            />
                        </div>
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Deputy"
                                selectedUsers={userToPersonas(explorationManagerDeputy)}
                                disabled={isReviewedEdit}
                                onFilterChanged={function (
                                    filterText: string,
                                    currentPersonas: IPersonaProps[],
                                    limitResults?: number
                                ): IPersonaProps[] | Promise<IPersonaProps[]> {
                                    return filterWriters(filterText, currentPersonas);
                                }}
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.ExplorationManagerDeputy);
                                }}
                                itemLimit={1}
                            />
                        </div>
                    </div>

                    <hr style={{ marginTop: "2em", marginBottom: "2rem" }} />
                    <span className="form-section-title">Area Manager</span>

                    <div className="field-row">
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Manager*"
                                selectedUsers={userToPersonas(areaManager)}
                                disabled={isReviewedEdit}
                                onFilterChanged={function (
                                    filterText: string,
                                    currentPersonas: IPersonaProps[],
                                    limitResults?: number
                                ): IPersonaProps[] | Promise<IPersonaProps[]> {
                                    return filterApprovers(filterText, currentPersonas);
                                }}
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.AreaManager);
                                }}
                                itemLimit={1}
                            />
                        </div>
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Deputy"
                                selectedUsers={userToPersonas(areaManagerDeputy)}
                                disabled={isReviewedEdit}
                                onFilterChanged={function (
                                    filterText: string,
                                    currentPersonas: IPersonaProps[],
                                    limitResults?: number
                                ): IPersonaProps[] | Promise<IPersonaProps[]> {
                                    return filterApprovers(filterText, currentPersonas);
                                }}
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.AreaManagerDeputy);
                                }}
                                itemLimit={1}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default UserRoles;
