import { InputInfo } from "../../Models/Options";
import { GlobalState } from "../RootReducer";
import store from "../Store";
import _ from "underscore";

export const SET_INPUT_INFOS = "SET_INPUT_INFOS";

interface SetInputInfosActionType {
    type: typeof SET_INPUT_INFOS;
    payload: InputInfo[];
}

export type GenericActionType = SetInputInfosActionType;

/**
 *
 * @param page page where the information should be shown
 * @param key information identifier
 * @returns the information
 */
export const getInputInfoValue = (page: string, key: string) => {
    let state: GlobalState = store.getState();
    let infos: InputInfo[] = state.generic.inputInfos.filter((x) => x.page === page && x.key === key);
    if (infos.length > 0) {
        return _.first(infos)?.message ?? null;
    }
    return null;
};

const setInputInfos = (inputInfos: InputInfo[]): SetInputInfosActionType => {
    return { type: SET_INPUT_INFOS, payload: inputInfos };
};

export const GenericActions = {
    setInputInfos,
};
