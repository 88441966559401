import { IPersonaProps } from '@fluentui/react';

import { GraphUser, IPersonaOptionDto } from '../Models/IUser';

export const bonifyEmailField = (email: string) => {
  if (email && email !== '') {
    return email;
  }
  return 'No email available';
};

export const optionsToPersonas = (users: IPersonaOptionDto[]) => {
  return users.map((u) => {
    return {
      key: u.id,
      text: `${u.firstName} ${u.lastName}`,
      secondaryText: bonifyEmailField(u.email),
    };
  }) as IPersonaProps[];
};

export const graphUserToPersonas = (users: GraphUser[]) => {
  return users.map((u) => {
    return {
      key: u.onPremisesSamAccountName,
      text: u.displayName,
      secondaryText: bonifyEmailField(u.mail),
    };
  }) as IPersonaProps[];
};

export const IntegrationUtils = {
  joinPersonasArrays: (personasArray: IPersonaProps[][]) => {
    let result: IPersonaProps[] = [];

    for (let i = 0; i < personasArray.length; i++) {
      let arr = personasArray[i];
      for (let j = 0; j < arr.length; j++) {
        result.push({ ...arr[j] });
      }
    }

    return result;
  },
  // resolvePeoplePickerVoices: async (
  //   filterText: string,
  //   currentPersonas: IPersonaProps[] | undefined,
  //   extendList?: IPersonaProps[]
  // ): Promise<IPersonaProps[]> => {
  //   if (filterText.length < 3) {
  //     return [];
  //   }

  //   let response: IAPIResponse = await ApiService.UserController.getUsers({
  //     search: filterText,
  //     limit: 20,
  //     offset: 0,
  //   });

  //   if (response.error == null) {
  //     let result = response.payload as IUser[];
  //     let selectedIds = (currentPersonas ?? []).map((p) => p.key);
  //     let users = result
  //       .filter((u) => selectedIds.indexOf(u.id) === -1)
  //       .map((u) => {
  //         return {
  //           id: u.id,
  //           name: `${u.firstName} ${u.lastName}`,
  //           secondaryText: u.email,
  //         };
  //       }) as IPersonaOptionDto[];

  //     if (extendList) {
  //       for (let i = 0; i < extendList.length; i++) {
  //         let id: string = (extendList[i] as any).key;
  //         if (id.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())) {
  //           users.push({
  //             id: id,
  //             name: id,
  //             secondaryText: 'GROUP',
  //           });
  //         }
  //       }
  //     }

  //     return optionsToPersonas(users);
  //   } else {
  //     return [];
  //   }
  // },
  // resolveIntegrationTags: async (
  //   filter: string,
  //   selectedItems: ITag[] | undefined,
  //   integration: string,
  //   values?: IOptionDto[]
  // ): Promise<ITag[]> => {
  //   if (filter.length < 3) {
  //     return [];
  //   }

  //   if (integration !== null) {
  //     let queryObject = {
  //       integrationId: (integration as string) || '',
  //       search: filter,
  //       limit: 20,
  //       offset: 0,
  //       queryColumn: 'name',
  //     };

  //     let response: IAPIResponse = await ApiService.IntegrationsController.getBOValues(queryObject, () => {});

  //     if (response.error == null) {
  //       const boList: any[] = response.payload;
  //       let tags = boList.map((item: any) => {
  //         let tag: ITag = {
  //           key: item.id,
  //           name: item.name,
  //         };
  //         return tag;
  //       });
  //       return tags.filter((t) => !(selectedItems || []).some((s) => s.key === t.key));
  //     } else {
  //       return [];
  //     }
  //   } else if (values !== undefined && values.length > 0) {
  //     return values
  //       .filter((v) => v.name.indexOf(filter) !== -1)
  //       .map((o) => {
  //         return {
  //           key: o.id,
  //           name: o.name,
  //         };
  //       }) as ITag[];
  //   }

  //   return [];
  // },
};
