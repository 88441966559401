import _ from "underscore";

import { Signers } from "../Services/Controllers/SpudLetterController";
import { KeyValuePair } from "./ApiTypes";
import { FileInfo } from "./FileUtils";
import { TightDto } from "./FormRoutesStates";
import { MissingDataNotification } from "./IFormData";
import { ITargetItem } from "./ITargetItem";
import { BaseUser, Country, IUser } from "./IUser";
import { OptionDto } from "./Options";
import { SpudLetterStatus } from "./SpudLetterStatus";
import { CoordinateFormatType } from "./CoordinateFormatType";

export interface DocwareInfo {
    signers: Signers[];
    documentId?: string;
    requester: OptionDto;
    requestDate?: Date;
}
export enum WorkflowRole {
    ExplorationManager,
    ExplorationManagerDeputy,
    AreaManager,
    AreaManagerDeputy,
    ProjectManager,
    ProjectManagerDeputy,
}

export interface WorkflowSigner extends BaseUser {
    workflowRole: WorkflowRole;
    //  UserSignStatusType UserSignStatus
}

export interface SpudLetterDto {
    spudLetterId: string;
    status: SpudLetterStatus;
    creationDate: Date;
    updateDate: Date;
    xwareTight: boolean;
    documentName: string;

    createdBy: BaseUser;
    locked: boolean;
    lockDate: Date;
    lockedBy: string;

    mainFile: FileInfo;
    attachments: FileInfo[];

    // docwareInfo: DocwareInfo;
    signers: WorkflowSigner[];
    missingDataNotifications: MissingDataNotification[];

    userTight: boolean;
    place: string;
    date?: Date;
    from: string;
    wellCode: string;
    wellName: string;
    referenceSystem: string;
    ownership: string;
    country: Country;
    blockName: string;
    operator: string;
    partnerships: string;
    eniOperated: boolean;
    onshoreOffshore: string;

    arisField: KeyValuePair;
    prospect: KeyValuePair;

    estimatedTargets: ITargetItem[];

    hiip: string;
    pos: string;
    trapType: string;
    formationAge: string;
    lithology: string;
    reservoirField: KeyValuePair;
    reservoirLevel: KeyValuePair[];
    hydrocarbonPhase: string;

    spudDate?: Date;
    rig: string;
    rigContractor: string;
    productionType: string;
    path: string;
    waterDepth: string;
    rotaryTable: string;
    coordinates: { latitude: string; longitude: string } | null;
    coordinateReferenceSystem: KeyValuePair;
    coordinateAdditionalInfo: string;
    coordinateFormat: CoordinateFormatType;
    plannedTVDSS: string;
    plannedMD: string;
    afeCostType: string;
    afeCostValue: string;
    afeDays: string;
    wcei: string;
    notes: string;
}

export const useSigners = (signers: WorkflowSigner[]) => {
    const areaManager = _.first(signers.filter((x) => x.workflowRole === WorkflowRole.AreaManager));
    const areaManagerDeputy = _.first(signers.filter((x) => x.workflowRole === WorkflowRole.AreaManagerDeputy));
    const explorationManager = _.first(signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManager));
    const explorationManagerDeputy = _.first(
        signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManagerDeputy)
    );
    const projectManager = _.first(signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManager));
    const projectManagerDeputy = _.first(
        signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManagerDeputy)
    );

    return {
        areaManager,
        areaManagerDeputy,
        explorationManager,
        explorationManagerDeputy,
        projectManager,
        projectManagerDeputy,
    };
};
