import { useContext } from "react";
import { match } from "ts-pattern";

import { FormContext } from "../../Pages/FormLayout/FormContext";
import AppRoutes from "../../Utils/AppRoutes";
import { FormMode } from "../../Utils/FormMode";
import GeneralInformation from "../GeneralInformation/GeneralInformation";
import LabelButton from "../LabelButton/LabelButton";
import Notes from "../Notes/Notes";
import ReservoirInformation from "../ReservoirFieldInformation/ReservoirInformation";
import Review from "../Review/Review";
import UserRoles from "../UserRoles/UserRoles";
import WellInformation from "../WellInformation/WellInformation";

interface NavButtonsProps {
    onBack?: () => void;
    onNext?: () => void;
    nextButton?: JSX.Element;
}
const NavButtons = ({ onBack, onNext, nextButton }: NavButtonsProps) => {
    return (
        <div className="nav-button-zone">
            <div>
                {onBack !== undefined && <LabelButton text="Back" icon="ChromeBack" whiteOutlined onClick={onBack} />}
            </div>
            {!!nextButton && nextButton}
            {!nextButton && onNext !== undefined && (
                <LabelButton text="Next" icon="ChromeBackMirrored" orangeSolid onClick={onNext} />
            )}
        </div>
    );
};

export interface FormRouteContainerProps {
    onSaveAndPreview: () => void;
    currentStep: number;
    mode: FormMode;
    increaseStep: () => void;
    decreaseStep: () => void;
    onEditFromReview: (step: number) => void;
}

/**
 * Component that sets up routes for form pages
 */
const FormRouteContainer = (props: FormRouteContainerProps) => {
    const routes = [
        <GeneralInformation mode={props.mode} />,
        <ReservoirInformation mode={props.mode} />,
        <WellInformation mode={props.mode} />,
        <Notes mode={props.mode} />,
        <UserRoles mode={props.mode} />,
        <Review onEditFromReview={props.onEditFromReview} />,
    ];

    if (props.mode === FormMode.View) {
        return routes[5];
    }

    let Buttons = match(props.currentStep)
        .with(0, () => <NavButtons onNext={props.increaseStep} />)
        .with(1, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(2, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(3, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(4, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(5, () => (
            <NavButtons
                onBack={props.decreaseStep}
                nextButton={<LabelButton text="Save and Preview" orangeSolid onClick={props.onSaveAndPreview} />}
            />
        ))
        .run();

    return (
        <>
            {routes[props.currentStep]}
            {Buttons}
        </>
    );
};

export default FormRouteContainer;
