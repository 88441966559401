import _ from "underscore";

import {
  CountryReservoirLevel,
  HydrocarbonPhase,
  IArisField,
  Prospect,
  ReservoirField,
  Rig,
  XwareCountry,
} from "../../Models/ApiTypes";
import { FileInfo } from "../../Models/FileUtils";
import { InputOption, Option } from "../../Models/Options";
import { MissingDataDialog } from "./FormState";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../RootReducer";
import store from "../Store";

export const SET_INPUT_OPTIONS = "SET_INPUT_OPTIONS";
export const SET_HYDROCARBON_PHASES = "SET_HYDROCARBON_PHASES";
export const ADD_ATTACHMENTS_ACTION = "ADD_ATTACHMENTS_ACTION";
export const SET_ATTACHMENTS_ACTION = "SET_ATTACHMENTS_ACTION";
export const DELETE_ATTACHMENT_ACTION = "DELETE_ATTACHMENT_ACTION";
export const SET_MISSING_DATA_DIALOG = "SET_MISSING_DATA_DIALOG";

export const SET_ARIS_FIELDS = "SET_ARIS_FIELDS";
export const SET_PROSPECTS = "SET_PROSPECTS";
export const SET_RIGS = "SET_RIGS";
export const SET_RESERVOIR_OPTIONS = "SET_RESERVOIR_OPTIONS";
export const SET_COUNTRY_OPTIONS = "SET_COUNTRY_OPTIONS";
export const SET_USERS = "SET_USERS";

interface SetInputOptionsActionType {
  type: typeof SET_INPUT_OPTIONS;
  payload: InputOption[];
}
interface SetHydrocarbonPhasesActionType {
  type: typeof SET_HYDROCARBON_PHASES;
  payload: HydrocarbonPhase[];
}
interface SetAttachmentsActionType {
  type: typeof SET_ATTACHMENTS_ACTION;
  payload: FileInfo[];
}
interface AddAttachmentsActionType {
  type: typeof ADD_ATTACHMENTS_ACTION;
  payload: FileInfo[];
}
interface DeleteAttachmentActionType {
  type: typeof DELETE_ATTACHMENT_ACTION;
  payload: string;
}
interface SetMissingDataDialogActionType {
  type: typeof SET_MISSING_DATA_DIALOG;
  payload: MissingDataDialog;
}
interface SetArisFieldsActionType {
  type: typeof SET_ARIS_FIELDS;
  payload: IArisField[];
}
interface SetProspectsActionType {
  type: typeof SET_PROSPECTS;
  payload: Prospect[];
}
interface SetRigsActionType {
  type: typeof SET_RIGS;
  payload: Rig[];
}
interface SetReservoirOptionsActionType {
  type: typeof SET_RESERVOIR_OPTIONS;
  payload: CountryReservoirLevel[];
}
interface SetCountryOptionsActionType {
  type: typeof SET_COUNTRY_OPTIONS;
  payload: XwareCountry[];
}
interface SetUsersActionType {
  type: typeof SET_USERS;
  payload: IUser[];
}

export type FormActionTypes =
  | SetInputOptionsActionType
  | AddAttachmentsActionType
  | DeleteAttachmentActionType
  | SetHydrocarbonPhasesActionType
  | SetMissingDataDialogActionType
  | SetAttachmentsActionType
  | SetArisFieldsActionType
  | SetProspectsActionType
  | SetRigsActionType
  | SetReservoirOptionsActionType
  | SetCountryOptionsActionType
  | SetUsersActionType;

const setInputOptions = (options: InputOption[]): SetInputOptionsActionType => {
  return { type: SET_INPUT_OPTIONS, payload: options };
};
const setHydrocarbonPhases = (
  options: HydrocarbonPhase[]
): SetHydrocarbonPhasesActionType => {
  return { type: SET_HYDROCARBON_PHASES, payload: options };
};
const setAttachments = (files: FileInfo[]): SetAttachmentsActionType => {
  return { type: SET_ATTACHMENTS_ACTION, payload: files };
};
const addAttachments = (files: FileInfo[]): AddAttachmentsActionType => {
  return { type: ADD_ATTACHMENTS_ACTION, payload: files };
};
const deleteAttachment = (fileId: string): DeleteAttachmentActionType => {
  return { type: DELETE_ATTACHMENT_ACTION, payload: fileId };
};

const setMissingDataDialog = (
  data: MissingDataDialog
): SetMissingDataDialogActionType => {
  return { type: SET_MISSING_DATA_DIALOG, payload: data };
};
const setArisFields = (data: IArisField[]): SetArisFieldsActionType => {
  return { type: SET_ARIS_FIELDS, payload: data };
};
const setProspects = (data: Prospect[]): SetProspectsActionType => {
  return { type: SET_PROSPECTS, payload: data };
};
const setRigs = (data: Rig[]): SetRigsActionType => {
  return { type: SET_RIGS, payload: data };
};
const setReservoirOptions = (
  data: CountryReservoirLevel[]
): SetReservoirOptionsActionType => {
  return { type: SET_RESERVOIR_OPTIONS, payload: data };
};
const setCountryOptions = (
  countries: XwareCountry[]
): SetCountryOptionsActionType => {
  return { type: SET_COUNTRY_OPTIONS, payload: countries };
};
const setUsers = (countries: IUser[]): SetUsersActionType => {
  return { type: SET_USERS, payload: countries };
};

export const getInputOptions = (name: string): Option[] => {
  let state: GlobalState = store.getState();
  let options: InputOption[] = state.form.options.filter(
    (x) => x.name === name
  );
  if (options.length > 0) {
    return _.first(options)!.options;
  }
  return [];
};

export const FormActions = {
  setInputOptions,
  addAttachments,
  deleteAttachment,
  setAttachments,
  setHydrocarbonPhases,
  setMissingDataDialog,
  setArisFields,
  setProspects,
  setRigs,
  setReservoirOptions,
  setCountryOptions,
  setUsers,
};
