import "./ReviewAccordion.scss";

import { useEffect, useRef, useState } from "react";

import { Icon } from "@fluentui/react";

export interface IReviewAccordionProps {
	title: string;
	onEditClicked: () => void;
	isEditable?: boolean;
}

const ReviewAccordion: React.FC<IReviewAccordionProps> = (props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div className="review-accordion-wrap">
			<div
				className="review-accordion-title"
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			>
				<div>
					<span style={{ marginRight: "1em", marginLeft: "0.5em" }}>
						<Icon className={!isOpen ? "" : "review-accordion-tilted-chevron"} iconName="ChevronRight" />
					</span>
					<span>{props.title}</span>
				</div>
				{isOpen && props.isEditable && (
					<span
						className="review-accordion-edit"
						onClick={(e) => {
							console.log("clicked");
							e.preventDefault();
							props.onEditClicked();
						}}
					>
						<Icon iconName="Edit" /> Edit
					</span>
				)}
			</div>
			<div className={"review-accordion-content"} style={isOpen ? {} : { maxHeight: "0px" }}>
				{props.children}
			</div>
		</div>
	);
};

export default ReviewAccordion;
