import {
  ADD_ATTACHMENTS_ACTION,
  DELETE_ATTACHMENT_ACTION,
  SET_ARIS_FIELDS,
  SET_ATTACHMENTS_ACTION,
  SET_COUNTRY_OPTIONS,
  SET_HYDROCARBON_PHASES,
  SET_INPUT_OPTIONS,
  SET_MISSING_DATA_DIALOG,
  SET_PROSPECTS,
  SET_RESERVOIR_OPTIONS,
  SET_RIGS,
  SET_USERS,
} from './FormActions';
import { DEFAULT_FORM_STATE, FormState } from './FormState';

export const formReducer = (state: FormState = DEFAULT_FORM_STATE, action: any): any => {
  switch (action.type) {
    case SET_INPUT_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case SET_HYDROCARBON_PHASES:
      return {
        ...state,
        hydrocarbonPhases: action.payload,
      };
    case SET_ATTACHMENTS_ACTION:
      return {
        ...state,
        attachments: action.payload,
      };
    case ADD_ATTACHMENTS_ACTION:
      return {
        ...state,
        attachments: (state?.attachments || []).concat(action.payload),
      };
    case DELETE_ATTACHMENT_ACTION:
      return {
        ...state,
        attachments: (state?.attachments || []).filter((a) => a.id !== action.payload),
      };
    case SET_MISSING_DATA_DIALOG:
      if (action.payload.fields.length === 0) {
        return {
          ...state,
          missingDataDialog: action.payload,
        };
      }
      return {
        ...state,
        missingDataDialog: {
          show: action.payload.show,
          fields: [...state.missingDataDialog.fields, ...action.payload.fields],
        },
      };
    case SET_ARIS_FIELDS:
      return {
        ...state,
        arisFields: action.payload,
      };
    case SET_PROSPECTS:
      return {
        ...state,
        prospects: action.payload,
      };
    case SET_RIGS:
      return {
        ...state,
        rigs: action.payload,
      };
    case SET_RESERVOIR_OPTIONS:
      return {
        ...state,
        reservoirOptions: action.payload,
      };
    case SET_COUNTRY_OPTIONS:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};
