import "./DegreesMinutesSecondsInput.scss";

import { useEffect, useState } from "react";

import { TextField } from "@fluentui/react";
import { decimalToDMS, dmsToDecimal } from "./DMS";

interface DegreeInputProps {
  defaultDecimalDegree: string;
  setDecimalDegree: (value: string) => void;
}

const DegreesMinutesSecondsInput = (props: DegreeInputProps) => {
  const { setDecimalDegree } = props;

  let dms = decimalToDMS(Number(props.defaultDecimalDegree));

  const [degrees, setDegrees] = useState<string>(dms.degrees.toFixed(0));
  const [minutes, setMinutes] = useState<string>(dms.minutes.toFixed(0));
  const [seconds, setSeconds] = useState<string>(dms.seconds.toFixed(4));

  useEffect(() => {
    if (degrees !== "" && minutes !== "" && seconds !== "") {
      let decimalDegrees = dmsToDecimal(
        Number(degrees),
        Number(minutes),
        Number(seconds)
      );
      if (!Number.isNaN(decimalDegrees)) {
        setDecimalDegree(decimalDegrees.toFixed(8));
      }
    }
  }, [degrees, minutes, seconds]);

  //onChange implements logic as toFixed(x) but without rounding
  //+1 because end is not included
  return (
    <div className="degree-container">
      <TextField
        className="degree-field"
        type="number"
        suffix={`°`}
        value={degrees}
        onChange={(_event, text) => {
          const index = text.indexOf(".");
          const endIndex = index === -1 ? text.length : index;
          setDegrees(text.slice(0, endIndex) ?? "");
        }}
      />
      <TextField
        className="degree-field"
        type="number"
        suffix={`'`}
        value={minutes}
        onChange={(_event, text) => {
          const index = text.indexOf(".");
          const endIndex = index === -1 ? text.length : index;
          setMinutes(text.slice(0, endIndex) ?? "");
        }}
      />
      <TextField
        className="degree-field"
        type="number"
        suffix={`"`}
        value={seconds}
        onChange={(_event, text) => {
          const index = text.indexOf(".");
          const endIndex = index === -1 ? text.length : index + 4 + 1;
          setSeconds(text.slice(0, endIndex) ?? "");
        }}
      />
    </div>
  );
};

export default DegreesMinutesSecondsInput;
