import { useContext } from "react";

import { LabelButton } from "@Eni/docware-fe-master";

import { FormSections } from "../../Constants/FormSections";
import { FormContext } from "../../Pages/FormLayout/FormContext";
import { getInputInfoValue } from "../../Reducers/Generic/GenericAction";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { FormMode } from "../../Utils/FormMode";

const Notes = ({ mode }: { mode: FormMode }) => {
    const { formState, setFormState } = useContext(FormContext);
    // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    return (
        <div className="form-summary-form-container">
            <div className="flex-row">
                <span className="form-section-title">{FormSections.NOTES.title}</span>
            </div>

            <div className="field-row">
                <div className="field-100">
                    <FluentUIDecorator
                        label="Notes"
                        info={getInputInfoValue("notes", "notes")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            placeholder: "Please insert here any other additional information",
                            multiline: true,
                            rows: 10,
                            value: formState.notes,
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, notes: text })),
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default Notes;
