import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import _ from 'underscore';
import { Option } from '../Models/Options';

export const concatRoute = (firstRoute: string, secondRoute: string) => {
  return `${firstRoute}/${secondRoute}`;
};

/**
 * Given an `obj`ect, a dot-separated `path` and a `value`,
 * insert that `value` into the object with that specific `path`,
 * creating all the sub-objects needed
 * E.g.
 * `injectValue({}, "a.b", 123) === {a: { b: 123 } }`
 * @param obj The object in which to insert the value
 * @param path The path where the value must be inserted. Sub-objects are separated by dots
 * @param value The value to be inserted
 * @returns
 */
export function injectValue(obj: any, path: string, value: any): any {
  let stepObj: any = obj;
  let splPath = path.split('.');
  for (let i = 0; i < splPath.length; i++) {
    let step = splPath[i];

    /** last step -> add value */
    if (i === splPath.length - 1) {
      stepObj[step] = value;
    } else {
      if (!stepObj.hasOwnProperty(step)) {
        stepObj[step] = {};
      }
      stepObj = stepObj[step];
    }
  }

  return obj;
}

export const INVERSE_DECIMAL_NUMBER_PATTERN = /[^0-9.]/g;
export const DECIMAL_NUMBER_WITH_SIGN_PATTERN = /((\+|-)?([0-9]+)(\.[0-9]+)?)/g;
export const DECIMAL_DEGREE_PATTERN =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const getNumericValue = (text: string) => text.replace(INVERSE_DECIMAL_NUMBER_PATTERN, '');
export const getSignedNumericValue = (text: string) => {
  let match = text.match(DECIMAL_NUMBER_WITH_SIGN_PATTERN);
  if (match) return match.toString();
  return '';
};

export const isDecimalDegree = (text: string) => DECIMAL_DEGREE_PATTERN.test(text);

export const getItemFromChoiceGroup = (options: IChoiceGroupOption[], key: string) => {
  return _.first(options.filter((option) => option.key === key));
};
export const getValueFromChoiceGroup = (options: IChoiceGroupOption[], key: string) => {
  return getItemFromChoiceGroup(options, key)?.text;
};
export const getItemFromDropdownOptions = (options: IDropdownOption[], key: string) => {
  if (!options) return null;
  return _.first(options.filter((option) => option.key === key));
};
export const getValueFromDropdownOptions = (options: IDropdownOption[], key: string) => {
  return getItemFromDropdownOptions(options, key)?.text;
};

export const getValueFromOptions = (options: Option[], key: string) => {
  return getItemFromOptions(options, key)?.text;
};
export const getItemFromOptions = (options: Option[], key: string) => {
  if (!options) return null;
  return _.first(options.filter((option) => option.key === key));
};

export const getReferenceSystemAbbr = (option: IChoiceGroupOption) => {
  if (option) return option.key ?? '?';
  return '?';
};

export const isNullOrEmpty = (value: string) => {
  if (!value) return true;
  if (value === '') return true;
  return false;
};

export const capitalize = (text?: string) => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};
