import { combineReducers } from 'redux';

import { formReducer } from './Form/FormReducer';
import { FormState } from './Form/FormState';
import { genericReducer } from './Generic/GenericReducer';
import { GenericState } from './Generic/GenericState';
import { toolbarReducer } from './Toolbar/ToolbarReducer';
import { ToolbarState } from './Toolbar/ToolbarState';

const rootReducer = combineReducers({
  generic: genericReducer,
  form: formReducer,
  toolbar: toolbarReducer,
});

export interface GlobalState {
  generic: GenericState;
  form: FormState;
  toolbar: ToolbarState;
}

export default rootReducer;
