import "./GeneralInformation.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";

import { IChoiceGroupOption, ITag, useFocusRects } from "@fluentui/react";

import { FormSections } from "../../Constants/FormSections";
import { XwareCountry } from "../../Models/ApiTypes";
import { propertyToFieldNameMap } from "../../Models/FormRoutesStates";
import { FormContext } from "../../Pages/FormLayout/FormContext";
import { getInputInfoValue } from "../../Reducers/Generic/GenericAction";
import { GlobalState } from "../../Reducers/RootReducer";
import { useInputOptions } from "../../Utils/Hooks";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import { FormMode } from "../../Utils/FormMode";

dayjs.extend(utc);

const GeneralInformation = ({ mode }: { mode: FormMode }) => {
    const { referenceSystemOptions, ownershipOptions, onshoreOptions } = useInputOptions();

    const { formState, setFormState } = useContext(FormContext);

    const countries = useSelector<GlobalState, XwareCountry[]>((state) => state.form.countries);

    // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    return (
        <div className="form-summary-form-container">
            <div className="flex-row">
                <span className="form-section-title">{FormSections.WELL_CODE_INFO.title}</span>
            </div>

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Place"
                        info={getInputInfoValue("general-info", "place")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            placeholder: "e.g.: San Donato Milanese",
                            value: formState.place ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, place: text })),
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Date"
                        info={getInputInfoValue("general-info", "date")}
                        fluentComponent={FluentUIDecoratorTypes.DatePicker({
                            placeholder: "Select",
                            value: formState.date,
                            onSelectDate: (date) => setFormState((prev) => ({ ...prev, date: date })),
                        })}
                    />
                </div>
            </div>

            <div style={{ marginBottom: "0.5em" }}>
                <div className="field-100">
                    <FluentUIDecorator
                        label="From"
                        info={getInputInfoValue("general-info", "from")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            placeholder: "e.g.: John Doe, EWAF…",
                            value: formState.from ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, from: text })),
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Well code"
                        info={getInputInfoValue("general-info", "well-code")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            maxLength: 255,
                            disabled: true,
                            value: formState.wellCode,
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, wellCode: text })),
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Well Name"
                        info={getInputInfoValue("general-info", "well-name")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            value: formState.wellName,
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, wellName: text })),
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Reference System"
                        info={getInputInfoValue("general-info", "reference-system")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.ChoiceGroup({
                            disabled: isReviewedEdit,
                            options: referenceSystemOptions as IChoiceGroupOption[],
                            selectedKey: formState.referenceSystem?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, referenceSystem: option })),
                            styles: choiceGroupHorizontalStyles,
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Ownership"
                        info={getInputInfoValue("general-info", "ownership")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.ChoiceGroup({
                            disabled: isReviewedEdit,
                            options: ownershipOptions,
                            selectedKey: formState.ownership?.key,
                            onChange: (event, option) =>
                                setFormState((prev) => ({ ...prev, ownership: option, signers: [] })),
                            styles: choiceGroupHorizontalStyles,
                        })}
                    />
                </div>
            </div>

            <hr style={{ marginTop: "2em", marginBottom: "2rem" }} />

            <span className="form-section-title">{FormSections.GENERAL_INFO.title}</span>

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Country"
                        info={getInputInfoValue("general-info", "country")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TagPicker({
                            disabled: isReviewedEdit,
                            pickerSuggestionsProps: {
                                suggestionsHeaderText: "Suggested countries",
                                noResultsFoundText: "No country found",
                            },
                            onResolveSuggestions: (filterText: string, tagList: ITag[]) => {
                                return countries
                                    .map((x) => ({ key: x.cntry_cd, name: x.cntry_desc }))
                                    .filter((x) => x.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
                            },
                            onChange: (items: ITag[]) => {
                                setFormState((prev) => ({ ...prev, country: _.first(items) }));
                            },
                            itemLimit: 1,
                            defaultSelectedItems: formState.country ? [formState.country] : [],
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label={propertyToFieldNameMap["blockName"]}
                        info={getInputInfoValue("general-info", "block-name")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            value: formState.blockName,
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, blockName: text })),
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label={propertyToFieldNameMap["operator"]}
                        info={getInputInfoValue("general-info", "operator")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            multiline: true,
                            autoAdjustHeight: true,
                            rows: 5,
                            // maxLength: 255,
                            value: formState.operator,
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, operator: text })),
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Partnership (Participating Interest)"
                        info={getInputInfoValue("general-info", "partnership")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            multiline: true,
                            autoAdjustHeight: true,
                            rows: 5,
                            // maxLength: 255,
                            value: formState.partnerships,
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, partnerships: text })),
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Eni operated"
                        info={getInputInfoValue("general-info", "eni-operated")}
                        fluentComponent={FluentUIDecoratorTypes.Toggle({
                            disabled: isReviewedEdit,
                            checked: formState.eniOperated,
                            onChange: (event, checked) => setFormState((prev) => ({ ...prev, eniOperated: checked })),
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Onshore/Offshore"
                        info={getInputInfoValue("general-info", "onshore-offshore")}
                        fluentComponent={FluentUIDecoratorTypes.ChoiceGroup({
                            disabled: isReviewedEdit,
                            options: onshoreOptions,
                            selectedKey: formState.onshoreOffshore?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, onshoreOffshore: option })),
                            styles: choiceGroupHorizontalStyles,
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default GeneralInformation;

const choiceGroupHorizontalStyles = {
    flexContainer: { display: "flex", flexDirection: "row", gridGap: "1em" },
};
