import ActivityController from "./Controllers/ActivityController";
import AreaController from "./Controllers/AreaController";
import DataOwnerController from "./Controllers/DataOwnerController";
import DocumentController from "./Controllers/DocumentController";
import EditLockController from "./Controllers/EditLockController";
import ErrorLogsController from "./Controllers/ErrorLogsController";
import InboundController from "./Controllers/InboundController";
import InputInfoController from "./Controllers/InputInfoController";
import InputOptionsController from "./Controllers/InputOptionsController";
import LatestExternalDataController from "./Controllers/LatestExternalDataController";
import RecipientController from "./Controllers/RecipientController";
import SpudLetterController from "./Controllers/SpudLetterController";
import UserController from "./Controllers/UserController";
import WorkflowController from "./Controllers/WorkflowController";

const ApiService = {
    SpudLetterController,
    InputInfoController,
    InputOptionsController,
    ActivityController,
    InboundController,
    UserController,
    DocumentController,
    AreaController,
    RecipientController,
    DataOwnerController,
    LatestExternalDataController,
    WorkflowController,
    EditLockController,
    ErrorLogsController,
};

export default ApiService;
