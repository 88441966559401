import "./FormPageInit.scss";

import { useEffect, useRef } from "react";
import _ from "underscore";

import {
  CountryReservoirLevel,
  IArisField,
  Prospect,
  ReservoirField,
  Rig,
  XwareCountry,
} from "../../Models/ApiTypes";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { useDispatch, useSelector } from "react-redux";
import { FormActions } from "../../Reducers/Form/FormActions";
import WindowToast from "../../Utils/WindowToast";
import { FormMode } from "../../Utils/FormMode";
import { GlobalState } from "../../Reducers/RootReducer";
import { SpudLetterDto } from "../../Models/SpudLetterDto";
import { useFormContext } from "../../Pages/FormLayout/FormContext";
import { IUser } from "../../Models/IUser";
import { useLoaderContext } from "../../Context/LoaderContext";

const FormPageInit = ({ mode }: { mode: FormMode }) => {
  const { formState, setFormState } = useFormContext();

  const prevCountryCode = useRef("");

  const dispatch = useDispatch();

  // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
  const loader = useLoaderContext();

  const notifications = formState.missingDataNotifications;

  const getCountries = () => {
    loader.show();
    ApiService.InboundController.getCountries()
      .then((response: IAPIResponse) => {
        if (response.error !== null)
          throw "There was an error loading the areas";
        let payload = response.payload as XwareCountry[];
        dispatch(FormActions.setCountryOptions(payload));
      })
      .catch(WindowToast.error)
      .finally(loader.hide);
  };

  const checkMissingField = (
    payload: any[],
    propertyName: keyof SpudLetterDto,
    propertyName2?: keyof SpudLetterDto
  ) => {
    if (mode === FormMode.Edit && payload.length === 0) {
      let notification = notifications.filter((x) => x.field === propertyName);
      if (_.isEmpty(notification) || !_.first(notification)!.notificationSent) {
        let fields = [propertyName];
        if (propertyName2) fields.push(propertyName2);
        dispatch(
          FormActions.setMissingDataDialog({
            show: true,
            fields,
          })
        );
      }
    }
  };

  const getArisFields = () => {
    if (!formState.country) return;
    loader.show();
    ApiService.InboundController.getArisFields(formState.country.key.toString())
      .then((response: IAPIResponse) => {
        if (response.error !== null) throw "Error retrieving aris fields!";
        let arisFields = response.payload as IArisField[];
        if (formState.ownership?.key !== "E") {
          checkMissingField(arisFields, "arisField");
        }
        dispatch(FormActions.setArisFields(arisFields));
      })
      .catch(WindowToast.error)
      .finally(loader.hide);
  };

  const getProspects = () => {
    if (!formState.country) return;
    loader.show();
    ApiService.InboundController.getProspects(formState.country.key.toString())
      .then((response: IAPIResponse) => {
        if (response.error !== null) throw "Error retrieving prospects";
        let prospects = response.payload as Prospect[];
        checkMissingField(prospects, "prospect");
        dispatch(FormActions.setProspects(prospects));
      })
      .catch(WindowToast.error)
      .finally(loader.hide);
  };

  const getReservoirFields = () => {
    if (!formState.country) return;
    loader.show();
    ApiService.InboundController.getCountryReservoirLevels(
      undefined,
      formState.country.key.toString()
    )
      .then((response: IAPIResponse) => {
        if (response.error !== null) throw "Error retrieving reservoir fields";
        let reservoirFields = response.payload as CountryReservoirLevel[];
        if (formState.ownership?.key !== "E") {
          checkMissingField(reservoirFields, "reservoirField");
        }
        dispatch(FormActions.setReservoirOptions(reservoirFields));
      })
      .catch(WindowToast.error)
      .finally(loader.hide);
  };

  const getRigs = () => {
    loader.show();
    ApiService.InboundController.getRigContractors(formState.wellCode)
      .then((response: IAPIResponse) => {
        if (response.error !== null) throw response.error;
        let rigs = response.payload as Rig[];
        checkMissingField(rigs, "rig", "rigContractor");
        if (
          formState.rig !== null &&
          formState.rig !== "" &&
          !rigs.some((x) => x.rigname === formState.rig)
        )
          rigs.push({
            wellida: formState.wellCode,
            wellName: formState.wellName,
            contractor: formState.rigContractor,
            rigname: formState.rig,
          });

        dispatch(FormActions.setRigs(rigs));
      })
      .catch((err) => WindowToast.error("Error retrieving rigs"))
      .finally(loader.hide);
  };

  const getUsers = () => {
    if (!formState.country) return;
    loader.show();
    ApiService.UserController.getCountryTightUsers(
      formState.wellCode,
      formState.country?.key?.toString()
    )
      .then((response: IAPIResponse) => {
        if (response.error !== null) throw response.error;
        let payload = response.payload as IUser[];
        dispatch(FormActions.setUsers(payload));
      })
      .catch((err) => WindowToast.error("There was an error loading the users"))
      .finally(loader.hide);
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (
      !formState.country ||
      formState.country.key.toString() === prevCountryCode.current
    )
      return;

    prevCountryCode.current = formState.country.key.toString();

    getArisFields();
    getProspects();
    getReservoirFields();
    getUsers();
  }, [formState.country]);

  useEffect(() => {
    if (formState.arisField === null) getReservoirFields();
  }, [formState.arisField]);

  useEffect(() => {
    if (!formState.wellCode) return;
    getRigs();
  }, []);

  return null;
};

export default FormPageInit;
