import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./RootReducer";

const composeEnhancers = composeWithDevTools({ name: "Docware Drive" });
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export type AppState = ReturnType<typeof rootReducer>;
export default store;

// develop only
if (window.location.href.indexOf("localhost") !== -1) {
  let w: any = window;
  w.store = store;
}
