import { createBrowserHistory } from "history";
import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { LoaderContextProvider } from "./Context/LoaderContext";
import { UserContextProvider } from "./Context/UserContext";
import store from "./Reducers/Store";

export const ProviderWrapper = (props: any) => {
  const history = createBrowserHistory();

  return (
    <React.StrictMode>
      <Provider store={store}>
        <LoaderContextProvider>
          <UserContextProvider>{props.children}</UserContextProvider>
        </LoaderContextProvider>
      </Provider>
    </React.StrictMode>
  );
};
