import { createContext, useEffect, useState, useContext, ReactPropTypes, PropsWithChildren, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import { IDropdownOption, Spinner } from "@fluentui/react";

import Suspense from "../../Components/Suspense/Suspense";
import { IFormState, InitialState } from "../../Models/FormRoutesStates";
import { fromKeyValuePair } from "../../Models/InboundSpudLetterDto";
import { SpudLetterDto } from "../../Models/SpudLetterDto";
import { FormActions } from "../../Reducers/Form/FormActions";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { useInputOptions } from "../../Utils/Hooks";
import { getItemFromDropdownOptions, getValueFromChoiceGroup } from "../../Utils/Methods";
import WindowToast from "../../Utils/WindowToast";
import { useLoaderContext } from "../../Context/LoaderContext";
import AppRoutes from "../../Utils/AppRoutes";

interface FormContextType {
	formState: IFormState;
	setFormState: React.Dispatch<React.SetStateAction<IFormState>>;
	refreshSpudLetter: () => void;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export const useFormContext = () => {
	return useContext(FormContext);
};

const FormContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const { wellCode } = useParams();

	if (!wellCode) return <div>Wrong well code!</div>;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		referenceSystemOptions,
		ownershipOptions,

		onshoreOptions,
		pathOptions,
		currencyOptions,
		mainFluidOptions,
		coordinateTypeOptions,
		loaded: optionsLoaded,
	} = useInputOptions();

	const [formState, setFormState] = useState<IFormState>(InitialState);
	const [loaded, setLoaded] = useState(false);
	const loader = useLoaderContext();

	const getSpudLetter = (isReload = false) => {
		loader.show();
		ApiService.SpudLetterController.getByWellCode(wellCode, (response: IAPIResponse) => {
			if (response.error) {
				if (response.raw.status === 403) {
					WindowToast.error("You are not authorized to access this Spud Letter");
				} else {
					WindowToast.error("There was an error retrieving the Spud Letter");
				}
				navigate(AppRoutes.HOME);
			} else {
				let payload = response.payload as SpudLetterDto;

				let notifications = payload.missingDataNotifications;

				let missingFields: (keyof SpudLetterDto)[] = [];
				const checkMissingField = (payload: SpudLetterDto, property: keyof SpudLetterDto) => {
					if (payload[property] === null) {
						let notification = notifications.filter((x) => x.field === property);
						if (_.isEmpty(notification) || !_.first(notification)!.notificationSent) {
							missingFields.push(property);
						}
					}
				};
				if (!isReload) {
					checkMissingField(payload, "wellName");
					checkMissingField(payload, "country");
					checkMissingField(payload, "blockName");
					checkMissingField(payload, "operator");
					checkMissingField(payload, "partnerships");
					checkMissingField(payload, "onshoreOffshore");
					checkMissingField(payload, "ownership");
					checkMissingField(payload, "wcei");
					checkMissingField(payload, "eniOperated");
					checkMissingField(payload, "path");

					if (missingFields.length > 0) {
						dispatch(
							FormActions.setMissingDataDialog({
								show: true,
								fields: missingFields,
							})
						);
					}
				}

				let dateOffset = new Date(payload.date ?? "").getTimezoneOffset() * 60000;
				let spudDateOffset = new Date(payload.spudDate ?? "").getTimezoneOffset() * 60000;

				setFormState({
					...payload,
					creationDate: new Date(payload.creationDate),
					updateDate: new Date(payload.updateDate),
					date: payload.date ? new Date(new Date(payload.date).getTime() + dateOffset) : undefined,
					spudDate: payload.spudDate
						? new Date(new Date(payload.spudDate).getTime() + spudDateOffset)
						: undefined,
					ownership: payload.ownership
						? {
								key: payload.ownership,
								text: getValueFromChoiceGroup(ownershipOptions, payload.ownership),
						  }
						: null,
					referenceSystem: payload.referenceSystem
						? {
								key: payload.referenceSystem,
								text: getValueFromChoiceGroup(referenceSystemOptions, payload.referenceSystem),
						  }
						: null,
					onshoreOffshore: payload.onshoreOffshore
						? {
								key: payload.onshoreOffshore,
								text: getValueFromChoiceGroup(onshoreOptions, payload.onshoreOffshore),
						  }
						: null,
					arisField: fromKeyValuePair(payload.arisField),
					prospect: fromKeyValuePair(payload.prospect),
					reservoirField: fromKeyValuePair(payload.reservoirField),
					reservoirLevel: payload.reservoirLevel.map((x) => fromKeyValuePair(x)),
					productionType: {
						key: payload.productionType,
						text: payload.productionType,
					},
					path: getItemFromDropdownOptions(pathOptions, payload.path),
					coordinateReferenceSystem: fromKeyValuePair(payload.coordinateReferenceSystem),
					// coordinateFormat: _.first(coordinateTypeOptions.filter((x) => x.key === "dms")),
					latitude: Math.abs(Number(payload.coordinates?.latitude ?? 0)).toString(),
					longitude: Math.abs(Number(payload.coordinates?.longitude ?? 0)).toString(),
					latDirection: +payload.coordinates?.latitude < 0 ? "S" : "N",
					lonDirection: +payload.coordinates?.longitude < 0 ? "W" : "E",
					afeCostType: getItemFromDropdownOptions(currencyOptions, payload.afeCostType),
					wcei: { key: payload.wcei, text: payload.wcei },
					trapType: { key: payload.trapType, text: payload.trapType },
					country: { key: payload.country.countryCode, name: payload.country.countryDescription },
				});

				dispatch(FormActions.setAttachments(payload.attachments));
			}
			loader.hide();
			setLoaded(true);
		});
	};

	useEffect(() => {
		getSpudLetter();
	}, []);

	useEffect(() => {
		if (optionsLoaded) {
			getSpudLetter(true);
		}
	}, [optionsLoaded]);

	return (
		<FormContext.Provider value={{ formState, setFormState, refreshSpudLetter: () => getSpudLetter(true) }}>
			{loaded && children}
		</FormContext.Provider>
	);
};

export default FormContextProvider;
