export const InputOptionsKeys = {
  TARGET_NAME: 'target-age',
  LITHOLOGY: 'lithology',
  CURRENCY: 'currency',
  PATH: 'path',
  PRODUCTION_TYPE: 'production-type',
  WCEI: 'wcei',
  TRAP_TYPE: 'trap-type',
  MAIN_FLUID: 'main-fluid',
  REFERENCE_SYSTEM: 'reference-system',
  OWNERSHIP: 'ownership',
  ONSHORE_OFFSHORE: 'onshore-offshore',
};
