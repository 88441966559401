export const concatUrl = (url1: string, url2: string, isAbsolute = true) => {
	if (url1.endsWith("/")) {
		url1 = url1.substring(0, url1.length - 1);
	}

	if (url2.startsWith("/")) {
		url2 = url2.substring(1, url2.length);
	}

	if (isAbsolute) return `/${url1}/${url2}`;

	return `${url1}/${url2}`;
};

//only to be used here
const concatRoute = (firstRoute: string, secondRoute: string) => {
	return `${firstRoute}/${secondRoute}`;
};

export const BASE = "/spud-letter";
export const HOME = `${BASE}`;
export const DETAIL = `${BASE}/detail/:wellCode`;
export const FORM = `${BASE}/form/:wellCode`;
export const ADMIN = `${BASE}/admin`;
export const LOGIN = `${BASE}/login`;

export const UNAUTHORIZED = `${BASE}/unauthorized`;

const AppRoutes = {
	BASE,
	HOME,
	DETAIL,
	FORM,
	ADMIN,
  LOGIN,
	UNAUTHORIZED,
};

export const DocwareRoutes = {
	DRIVE_DOCUMENT__MANAGMENT: "drive/document-management",
};

export default AppRoutes;
