import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";
import { useUserContext } from "../../Context/UserContext";

const ProtectedRoute = (props: any) => {
  const { user } = useUserContext();

  const location = useLocation();

  const redirectUrl = `/spud-letter/login?route=${location.pathname}`;

  return user === undefined ? <Navigate to={redirectUrl} /> : <Outlet />;
};

export default ProtectedRoute;
