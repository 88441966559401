import { Outlet, useNavigation } from "react-router-dom";
import { Bootstrapper } from "./Components/Bootstrapper/Bootstrapper";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import Loader from "./Components/Loader/Loader";
import SpudLetterUserMenu from "./Components/SpudLetterUserMenu/SpudLetterUserMenu";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";

export const enhanceAppTitle = (title: string | null) => {
  let futureTitle = "Spud Letter";

  if (title) {
    futureTitle = "Spud Letter - " + title;
  }

  if (document.title !== futureTitle) {
    document.title = futureTitle;
  }
};

export const AppLayout = () => {
  const { state } = useNavigation();

  return (
    <div style={{ overflow: "hidden" }}>
      <ErrorBoundary
        fallback={<ErrorPage title={""} message={""} page={"Application"} />}
      >
        {state === "loading" && <Loader display={true} />}
        <Bootstrapper>
          <SpudLetterUserMenu />
          <Outlet />
        </Bootstrapper>
      </ErrorBoundary>
    </div>
  );
};
