import "./AttachmentContainer.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Banner, LabelButton, ModalDialog } from "@Eni/docware-fe-master";
import { Spinner } from "@fluentui/react/lib/Spinner";

import { FileInfo, FileUtils } from "../../Models/FileUtils";
// import { NewDocumentAction } from '../../Reducers/NewDocument/NewDocumentActions';
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import Icons from "../../Utils/FabricIconsOutlet";
import { DragAndDropContainer } from "../DragAndDropContainer/DragAndDropContainer";
import FileUploadBoxPreview from "../FileUploadBoxPreview/FileUploadBoxPreview";
import { FormActions } from "../../Reducers/Form/FormActions";
import { APISettings } from "../../Services/Internal/AjaxService";
import { DOCUMENT_URLS } from "../../Constants/ApiUrls";
import WindowToast from "../../Utils/WindowToast";
import { useLoaderContext } from "../../Context/LoaderContext";

interface AttachmentContainerProps {
    wellCode: string;
    preloadFiles: FileInfo[];
    onAttachmentDelete: (attachmentId: string) => void;
    onAddAttachments: (files: File[]) => Promise<ErrorType>;
    disabled: boolean;
}

export type ErrorType = "generic" | "not-supported-format" | ""

var FILES: File[] = [];

export const AttachmentContainer = (props: AttachmentContainerProps) => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [errorType, setErrorType] = useState<ErrorType>("");

    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    const loader = useLoaderContext();

    // const documentId = useSelector<GlobalState, string>((state) => state.newDocument.document.documentId);

    const getContainerContent = () => {
        if (errorType === "generic") {
            return {
                icon: <div style={{ color: "#a4262c" }}>{Icons.getIcon("SyncToPC", "2em")}</div>,
                message: (
                    <div style={{ color: "#a4262c" }}>
                        Ops! <strong>There was an issue uploading.</strong>
                    </div>
                ),
                buttonLabel: "Drag or upload file again",
            };
        }
        if (errorType === "not-supported-format") {
            return {
                icon: <div style={{ color: "#a4262c" }}>{Icons.getIcon("StatusErrorFull", "2em")}</div>,
                message: (
                    <div style={{ color: "#a4262c" }}>
                        Ouch! <strong>Format not supported!</strong>
                    </div>
                ),
                buttonLabel: "Upload another file",
            };
        }

        return {
            icon: Icons.getIcon("CloudUpload", "2em"),
            message: <div>Upload any attachment file.</div>,
            buttonLabel: "Choose attachments",
        };
    };

    useEffect(() => {}, [props.preloadFiles]);

    const proceedAndUpload = async () => {
        setErrorMsg("");
        if (FILES != null) {
            if (FILES.length > 0) {
                props.onAddAttachments(FILES).then((value) => setErrorType(value));
            }
        }
    };

    const onDropFiles = (files: File[]) => {
        FILES = files;
        proceedAndUpload();
    };

    const content = getContainerContent();

    return (
        <div style={{ cursor: "pointer" }}>
            <DragAndDropContainer
                multiple={true}
                disableInternalListing={true}
                preLoadFiles={props.preloadFiles}
                onDrop={onDropFiles}
                lowerElement={
                    <div className="fake-drag-drop-button">
                        <LabelButton text={content.buttonLabel} orangeSolid icon={"OpenFile"} onClick={() => {}} />
                    </div>
                }
                enabled={!props.disabled}
                icon={content.icon}
                title={content.message}
                allowFileListing={true}
                topOfListView={<Banner type={"error"} message={errorMsg} enabled={errorMsg.length > 0} />}
                customListView={(items: FileInfo[]) => {
                    return items.map((x, i) => {
                        return (
                            <div style={{ margin: "1em" }} key={i}>
                                <FileUploadBoxPreview
                                    fileId={x.id}
                                    fileName={x.fileName}
                                    fileSize={x.size}
                                    lastUpdated={x.uploadTime}
                                    onExitClick={props.disabled ? () => {} : props.onAttachmentDelete}
                                    onClick={() => {
                                        FileUtils.downloadFromUrl(
                                            `${APISettings.baseUrl}${DOCUMENT_URLS.ATTACHMENT.replace(
                                                "{wellCode}",
                                                props.wellCode
                                            ).replace("{attachmentId}", x.id)}`,
                                            x.fileName
                                        );
                                    }}
                                />
                            </div>
                        );
                    });
                }}
            />
        </div>
    );
};
