import { PartialTheme } from '@fluentui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import packageJson from '../package.json';
import './App.scss';
import { AppProvider } from './app/main-provider';
import { createRouter } from './app/router';
import './config/i18n';

// SET THE APP THEME COLORS HERE AND ONLY HERE
// https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/
// REMINDER: change also color in css classes .ms-DetailsRow-check and .ms-DetailsRow-cell for changing file list checkbox colors
const appTheme: PartialTheme = {
    palette: {
        themePrimary: '#3b84aa',
        themeLighterAlt: '#f5f9fc',
        themeLighter: '#d8e9f2',
        themeLight: '#b9d6e6',
        themeTertiary: '#7db1cd',
        themeSecondary: '#4d91b5',
        themeDarkAlt: '#36779a',
        themeDark: '#2d6482',
        themeDarker: '#214a60',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
};

const AppRouter = () => {
    const queryClient = useQueryClient();

    const router = useMemo(() => createRouter(queryClient), [queryClient]);

    return <RouterProvider router={router} />;
};

export const App = () => {
    useEffect(() => {
        console.log('PACKAGE JSON VERSION', packageJson.version);
    }, []);
    return (
        <AppProvider>
            <AppRouter />
        </AppProvider>
    );
};
