import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";

import { AppLayout } from "./AppLayout";
import FormContextProvider from "./Pages/FormLayout/FormContext";
import { FormErrorBoundary, FormLayout } from "./Pages/FormLayout/FormLayout";
import AppRoutes from "./Utils/AppRoutes";
import ErrorLogList from "./Components/ErrorLogList/ErrorLogList";
import ProtectedRoute from "./Components/Router/ProtectedRoute";
import { Login } from "./Pages/Login/Login";

const routes = createRoutesFromElements(
  <Route path="/spud-letter">
    <Route path="" element={<ProtectedRoute />}>
      <Route path="" element={<AppLayout />}>
        <Route index lazy={() => import("./Pages/Home/Home")} />
        <Route
          path="form/:wellCode"
          element={
            <FormContextProvider>
              <FormLayout />
            </FormContextProvider>
          }
          errorElement={<FormErrorBoundary />}
        />
        <Route
          path="detail/:wellCode"
          lazy={() => import("./Pages/Detail/Detail")}
        />

        {/* admin authorized paths */}
        <Route
          path="admin"
          lazy={() => import("./Pages/AdminPage/AdminPage")}
        />
        <Route path="dev" lazy={() => import("./Pages/Dev/DevLayout")}>
          <Route index element={<Navigate to={"error-logs"} />} />
          <Route
            path="error-logs"
            lazy={() => import("./Pages/Dev/ErrorLogList/ErrorLogList")}
          />
        </Route>
      </Route>
    </Route>
    {/* unauthenticated paths */}
    <Route
      path="unauthorized"
      lazy={() => import("./Pages/Unauthorized/Unauthorized")}
    />
    <Route path="login" element={<Login />} />
    <Route path="*" lazy={() => import("./Pages/NotFound/NotFound")} />
  </Route>
);

export default createBrowserRouter(routes);
