const dispatchEvent = (text: string, type: string) => {
  window.document.dispatchEvent(
    new CustomEvent('api-toast-result', {
      detail: {
        text,
        type,
      },
    })
  );
};

const WindowToast = {
  error: (text: string) => dispatchEvent(text, 'error'),
  warning: (text: string) => dispatchEvent(text, 'warning'),
  info: (text: string) => dispatchEvent(text, 'info'),
  success: (text: string) => dispatchEvent(text, 'success'),
};

export default WindowToast;
