export enum FormMode {
    Edit,
    View,
    ReviewedEdit
}

// export const isUpsertMode = (mode: FormMode) => {
//     // return mode === FormMode.Edit || mode === FormMode.Create;
//     return mode in [FormMode.Edit, FormMode.Update];
// }
// export const isViewMode = (mode: FormMode) => {
//     return mode === FormMode.View;
// }