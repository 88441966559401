export interface DMS {
    degrees: number;
    minutes: number;
    seconds: number;
}

// formula for conversion can be found at https://en.wikipedia.org/wiki/Decimal_degrees
export const decimalToDMS = (decimalDegrees: number): DMS => {
    const absDecimalDegrees = Math.abs(decimalDegrees);
    const degrees = Math.floor(absDecimalDegrees);
    const decimalMinutes = (absDecimalDegrees - degrees) * 60;
    const minutes = Math.floor(decimalMinutes);
    const seconds = (decimalMinutes - minutes) * 60;

    return {
        degrees,
        minutes,
        seconds,
    };
};

export const dmsToDecimal = (degrees: number, minutes: number, seconds: number): number => {
    const decimalDegrees = degrees + minutes / 60 + seconds / 3600; // * sign;

    return decimalDegrees;
};
