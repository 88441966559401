import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { FormActions } from "../../Reducers/Form/FormActions";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { useForceUpdate, useInputOptions } from "../../Utils/Hooks";
import Loader from "../Loader/Loader";

export const Bootstrapper = (props: { children: any }) => {
    const dispatch = useDispatch();

    const { loaded } = useInputOptions();

    const getInputInfos = () => {
        ApiService.InputInfoController.getInputInfos((response: IAPIResponse) => {
            if (response.error == null) {
                dispatch(GenericActions.setInputInfos(response.payload));
            } else {
                dispatch(GenericActions.setInputInfos([]));
            }
        });
    };

    const getInputOptions = () => {
        ApiService.InputOptionsController.getInputOptions((response: IAPIResponse) => {
            if (response.error == null) {
                dispatch(FormActions.setInputOptions(response.payload));
            } else {
                dispatch(FormActions.setInputOptions([]));
            }
        });
    };

    const getHydrocarbonPhases = () => {
        ApiService.InboundController.getHydrocarbonPhases((response: IAPIResponse) => {
            if (response.error == null) {
                dispatch(FormActions.setHydrocarbonPhases(response.payload));
            } else {
                dispatch(FormActions.setHydrocarbonPhases([]));
            }
        });
    };

    useEffect(() => {
        getInputInfos();
        getInputOptions();
        getHydrocarbonPhases();
    }, []);

    // Execute here ajax calls at the loading of the app

    if (!loaded) {
        return <Loader display={true} text="Loading basic info..." />;
    }

    return props.children;
};
