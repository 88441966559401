import {
  AuthenticationHelper,
  Banner,
  SharedState,
} from "@Eni/docware-fe-master";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useUserContext } from "../../Context/UserContext";
import { IUser } from "../../Models/IUser";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import AppRoutes from "../../Utils/AppRoutes";

export function Login() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const route = searchParams.get("route");

  const { user, setUser } = useUserContext();

  const postLoginProcess = () => {
    ApiService.UserController.getSelf().then((response: IAPIResponse) => {
      if (response.error !== null) {
        navigate(AppRoutes.UNAUTHORIZED);
      } else {
        let user = response.payload as IUser;
        setUser(user);
      }
    });
  };

  /**
   * What should happen when the login procedure fails
   */
  function failedLoginProcess(e: any) {
    console.error(e);
    console.error("[Auth] Failed login process");
    setUser(undefined);
  }

  const startLoginRoutine = async () => {
    AuthenticationHelper.startLoginRoutine().catch((e) => {
      console.error(e);
      failedLoginProcess(e);
    });
  };

  useEffect(() => {
    if (user !== undefined) {
      return;
    }
    if (localStorage.getItem("do-not-start-the-login") === "true") {
      localStorage.removeItem("do-not-start-the-login");
    } else {
      SharedState.authStatus.subscribe((status) => {
        console.debug(
          "[auth] SharedState.authStatus subscribe. status: ",
          status
        );
        if (status.error) {
          failedLoginProcess(status.error?.errorDetails);
          return;
        }
        if (status.accessToken) {
          postLoginProcess();
        } else {
          if (!status.pending) {
            startLoginRoutine();
          }
        }
      });
    }
  }, []);

  if (user === undefined) {
    return (
      <div style={{ overflow: "hidden" }}>
        <Loader display={true} text="Working with Eni login..." />
      </div>
    );
  }
  if (user.roles === null || user.roles.length === 0) {
    return (
      <div style={{ overflow: "hidden" }}>
        <Navigate to={AppRoutes.UNAUTHORIZED} />
      </div>
    );
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <Navigate to={route !== null ? route : AppRoutes.HOME} />
    </div>
  );
}
