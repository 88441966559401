import "./FormMenuTabs.scss";

import { MenuTabs } from "@Eni/docware-fe-master";

import { FormSections } from "../../Constants/FormSections";

export interface FormMenuTabsProps {
	step: number;
	setStep: (step: number) => void;
}

const FormMenuTabs = ({ step, setStep }: FormMenuTabsProps) => {
	let { GENERAL_INFO, RESERVOIR_INFO, WELL_INFO, NOTES, USER_ROLES, REVIEW } = FormSections;

	const percentages = [0, 25, 50, 75, 90, 100];

	let tabs = [
		{
			label: "Well Code Information",
			callback: () => setStep(0),
			isPassed: step > 0,
			isEvident: step === 0,
		},
		{
			label: RESERVOIR_INFO.title,
			callback: () => setStep(1),
			isPassed: step > 1,
			isEvident: step === 1,
		},
		{
			label: WELL_INFO.title,
			callback: () => setStep(2),
			isPassed: step > 2,
			isEvident: step === 2,
		},
		{
			label: NOTES.title,
			callback: () => setStep(3),
			isPassed: step > 3,
			isEvident: step === 3,
		},
		{
			label: USER_ROLES.title,
			callback: () => setStep(4),
			isPassed: step > 4,
			isEvident: step === 4,
		},
		{
			label: REVIEW.title,
			callback: () => setStep(5),
			isPassed: step > 5,
			isEvident: step === 5,
		},
	];

	return <MenuTabs tabs={tabs} leftText={""} rightText={`${percentages[step].toString()}%`} />;
};

export default FormMenuTabs;
