import { createContext, PropsWithChildren, useContext, useState } from "react";

import { IUser } from "../Models/IUser";

export interface IUserContext {
  user: IUser | undefined;
  setUser: (user: IUser) => void;
}

const LOADER_CONTEXT_DEFAULT = {
  user: undefined,
  setUser: (_) => { },
} as IUserContext;

export const UserContext = createContext<IUserContext>(LOADER_CONTEXT_DEFAULT);
export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = (props: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const userContextValue: IUserContext = {
    user,
    setUser,
  };

  return (
    <UserContext.Provider value={userContextValue}>
      {props.children}
    </UserContext.Provider>
  );
};
