import "./FormLayout.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Params, useLocation, useNavigate, useParams, useRouteError } from "react-router-dom";
import _ from "underscore";

import { ModalDialog } from "@Eni/docware-fe-master";

import { enhanceAppTitle } from "../../AppLayout";
import ActivityColumn, { IActivity } from "../../Components/ActivityColumn/ActivityColumn";
import FormMenuTabs from "../../Components/FormMenuTabs/FormMenuTabs";
import FormPageInit from "../../Components/FormPageInit/FormPageInit";
import FormRouteContainer from "../../Components/FormRouteContainer/FormRouteContainer";
import MissingDataDialog from "../../Components/MissingDataDialog/MissingDataDialog";
import PendingChangesManager from "../../Components/PendingChangesManager/PendingChangesManager";
import { SpudLetterManagementToolbar } from "../../Components/SpudLetterManagementToolbar/SpudLetterManagementToolbar";
import { useLoaderContext } from "../../Context/LoaderContext";
import { useUserContext } from "../../Context/UserContext";
import { stateToDto } from "../../Models/InboundSpudLetterDto";
import { FormActions } from "../../Reducers/Form/FormActions";
import { NavbarState } from "../../Reducers/Toolbar/ToolbarState";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import AppRoutes from "../../Utils/AppRoutes";
import { FormMode } from "../../Utils/FormMode";
import WindowToast from "../../Utils/WindowToast";
import ErrorPage from "../ErrorPage/ErrorPage";
import EditingTimeoutManager from "./EditingTimeoutManager";
import { useFormContext } from "./FormContext";
import FormSummaryDragAndDrop from "./FormSummaryDragAndDrop";
import FormSummary from "../../Components/FormSummary/FormSummary";

dayjs.extend(utc);

export async function loader({ request, params }: { request: any; params: Params<string> }) {
    return null;
}

export function FormLayout() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { state } = useLocation();
    const { wellCode } = useParams();

    const [mode, setMode] = useState<FormMode | undefined>(() => (state === null ? FormMode.View : state.mode));

    const { user: loggedUser } = useUserContext();

    const [activities, setActivities] = useState<IActivity[]>([]);
    const [step, setStep] = useState(() => {
        if (mode === FormMode.View) return 5;
        if (!isNaN(Number(state.step))) return Number(state.step);
        return 0;
    });

    const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);

    const { formState, setFormState } = useFormContext();
    const loader = useLoaderContext();

    if (formState.wellCode && formState.locked && formState.lockedBy !== loggedUser.id && mode === FormMode.Edit) {
        setMode(FormMode.View);
        WindowToast.error("Spud Letter is locked");
        setStep(5);
    }
    //call update API
    const saveSpudLetter = (goToPreview: boolean) => {
        loader.show();
        ApiService.SpudLetterController.update(stateToDto(formState))
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw response.error;

                WindowToast.success("File saved successfully!");
                if (goToPreview) {
                    navigate(AppRoutes.DETAIL.replace(":wellCode", wellCode));
                }
            })
            .catch((err) => WindowToast.error("There was an error saving the file"))
            .finally(loader.hide);
    };

    useEffect(() => {
        enhanceAppTitle(formState.wellCode);
    }, [formState.wellCode]);

    useEffect(() => {
        if (formState.wellCode && mode === FormMode.View) {
            setStep(5);
        }
    }, [mode, formState.wellCode]);

    const getDocumentActivities = () => {
        loader.show();
        ApiService.ActivityController.getDocumentActivities(wellCode)
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw response.error;
                setActivities(response.payload);
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    useEffect(() => {
        getDocumentActivities();

        return () => {
            dispatch(
                FormActions.setMissingDataDialog({
                    show: false,
                    fields: [],
                })
            );
        };
    }, []);

    //NB this would make the toolbar scroll under the header and become not visible
    //to fix this, the css class .page-wrap has position: relative and top: 48px
    //this class has to be applied on every page
    //applayout too has scrollIntoView, to fix the fact that the toolbar would start at double the top value
    const dummyRef = useRef(null);

    // useEffect(() => {
    //   dummyRef.current.scrollIntoView();
    // }, [pathname]);

    return (
        <div className="spud-letter-form-wrap" id="form-wrap">
            <div id="top-element"> </div>
            {(mode === FormMode.Edit || mode === FormMode.ReviewedEdit) && <FormPageInit mode={mode} />}
            <SpudLetterManagementToolbar
                type={NavbarState.SpudLetterForm}
                currentFile={{
                    wellCode,
                    locked: formState.locked,
                    status: formState.status,
                    ownership: formState.ownership?.key,
                    signers: formState.signers,
                }}
            />

            <div className="form-inner-section">
                <div className="form-header">
                    <div className="form-title" ref={dummyRef}>
                        Spud Letter
                    </div>
                </div>

                <div className="form-content">
                    <section className="form-left-section">
                        <div className="form-section-title">DOCUMENT INFORMATION</div>

                        <FormSummary
                            enabledButtons={mode === FormMode.Edit || mode === FormMode.ReviewedEdit}
                            onDiscard={() => setShowDiscardModal(true)}
                            onSave={() => saveSpudLetter(false)}
                        />

                        <FormSummaryDragAndDrop wellCode={wellCode} mode={mode} />

                        <div style={{ padding: "1em" }}>
                            <div style={{ margin: "2em 0 1em 0" }}>History</div>
                            <div className="summary-container">
                                <ActivityColumn
                                    onRenderLink={(reference: IActivity) => {
                                        let message = reference.message;
                                        let creator =
                                            reference.userId === loggedUser.id ? "You" : reference.userFullName;
                                        return (
                                            <Link to={""} className="activity-highlight-name">
                                                {creator}
                                            </Link>
                                        );
                                    }}
                                    title={"History"}
                                    activityList={activities}
                                    showUserName
                                    hideAction
                                />
                            </div>
                        </div>
                    </section>

                    <section className="form-right-section">
                        <div className="form-section-title">DETAILS</div>

                        {(mode === FormMode.Edit || mode === FormMode.ReviewedEdit) && (
                            <FormMenuTabs step={step} setStep={(step: number) => setStep(step)} />
                        )}

                        <FormRouteContainer
                            onSaveAndPreview={() => saveSpudLetter(true)}
                            currentStep={step}
                            increaseStep={() => setStep((prev) => prev + 1)}
                            decreaseStep={() => setStep((prev) => prev - 1)}
                            mode={mode}
                            onEditFromReview={(step: number) => {
                                setStep(step);
                                setMode(FormMode.Edit);
                            }}
                        />

                        {mode === FormMode.Edit && <PendingChangesManager />}
                    </section>
                </div>

                <ModalDialog
                    modalTitle={"Discard current changes?"}
                    modalMessage={"The current changes will be lost"}
                    enableModal={showDiscardModal}
                    onAbort={() => setShowDiscardModal(false)}
                    onAccept={() => {
                        setShowDiscardModal(false);
                        navigate(AppRoutes.BASE);
                    }}
                />

                {mode === FormMode.Edit && (
                    <EditingTimeoutManager wellCode={formState.wellCode} key={formState.wellCode} />
                )}

                {mode === FormMode.Edit && <MissingDataDialog />}
            </div>
        </div>
    );
}

export function FormErrorBoundary() {
    let error = useRouteError() as Error;
    return <ErrorPage title={error.name} message={error.message} page="Form" />;
}

FormLayout.displayName = "FormLazyRoute";
FormErrorBoundary.displayName = "FormErrorBoundary";
