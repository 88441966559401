import { ACTIVITY_URLS } from '../../Constants/ApiUrls';
import { createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse } from '../Internal/AjaxService';

const ActivityController = {
  getUserActivities: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ACTIVITY_URLS.GET_ACTIVITIES_BY_USER;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocumentActivities: async (wellCode: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ACTIVITY_URLS.GET_ACTIVITIES_BY_REF.replace('{wellCode}', wellCode);
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default ActivityController;
