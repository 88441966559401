import {
    CoordinateRef,
    CountryReservoirLevel,
    HydrocarbonPhase,
    IArisField,
    Prospect,
    ReservoirField,
    Rig,
    XwareCountry,
} from "../../Models/ApiTypes";
import { FileInfo } from "../../Models/FileUtils";
import { IFormData, IFormFields } from "../../Models/IFormData";
import { IUser } from "../../Models/IUser";
import { InputOption } from "../../Models/Options";
import { SpudLetterDto } from "../../Models/SpudLetterDto";
import { FormMode } from "../../Utils/FormMode";

export interface MissingDataDialog {
    show: boolean;
    fields: (keyof SpudLetterDto)[];
}

export interface FormState {
    options: InputOption[];
    attachments: FileInfo[];
    hydrocarbonPhases: HydrocarbonPhase[];

    errors: string[];
    arisFields: IArisField[];
    users: IUser[];

    countries: XwareCountry[];
    missingDataDialog: MissingDataDialog;
    prospects: Prospect[];
    rigs: Rig[];
    reservoirOptions: CountryReservoirLevel[];
}

export const DEFAULT_FORM_STATE: FormState = {
    // options: Options as InputOption[],
    options: [],
    hydrocarbonPhases: [],
    countries: [],

    attachments: [],

    errors: [],
    users: [],

    arisFields: [],
    prospects: [],
    rigs: [],
    reservoirOptions: [],

    missingDataDialog: {
        show: false,
        fields: [],
    },
};
