import "./WellInformation.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LatLngExpression } from "leaflet";
import { useContext, useEffect } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import _ from "underscore";

import { Dropdown, IComboBox, IDropdownOption, ITag } from "@fluentui/react";

import { FormSections } from "../../Constants/FormSections";
import { CoordinateRef, Rig } from "../../Models/ApiTypes";
import { FormContext } from "../../Pages/FormLayout/FormContext";
import { getInputInfoValue } from "../../Reducers/Generic/GenericAction";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { FormMode } from "../../Utils/FormMode";
import { useInputOptions } from "../../Utils/Hooks";
import { getReferenceSystemAbbr } from "../../Utils/Methods";
import WindowToast from "../../Utils/WindowToast";
import DegreesMinutesSecondsInput from "../DegreesMinutesSecondsInput/DegreesMinutesSecondsInput";
import FieldRow from "../FieldRow/FieldRow";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import { CoordinateFormatType } from "../../Models/CoordinateFormatType";

dayjs.extend(utc);

export interface RigState {
    rigId: string;
    rigName: string;
    contractorId: string;
    contractorName: string;
}

const ChangeView = ({ center, zoom, disabled = false }) => {
    if (!disabled) {
        const map = useMap();
        map.setView(center, zoom);
    }
    return null;
};

const WellInformation = ({ mode }: { mode: FormMode }) => {
    const { formState, setFormState } = useContext(FormContext);

    let center: LatLngExpression = {
        lat: isNaN(+formState.latitude)
            ? 0
            : formState.latDirection === "N"
            ? +formState.latitude
            : -formState.latitude,
        lng: isNaN(+formState.longitude)
            ? 0
            : formState.lonDirection === "E"
            ? +formState.longitude
            : -formState.longitude,
    };
    let zoom = 6;

    const crsRequired = center.lat !== 0 || center.lng !== 0;

    const rigOptions = useSelector<GlobalState, Rig[]>((state) => state.form.rigs);

    const coordinateTypes = Object.keys(CoordinateFormatType)
        .filter((x) => isNaN(Number(x)))
        .map((x) => ({
            key: CoordinateFormatType[x],
            text: x.toString(),
        }));

    const isMapEnabled = formState.coordinateReferenceSystem?.name
        ? formState.coordinateReferenceSystem.name.toUpperCase().includes("WGS 84")
        : false;

    const getCRS = async (search: string) => {
        let response = await ApiService.InboundController.getCRS(search);
        if (response.error === null) {
            return response.payload as CoordinateRef[];
        } else {
            WindowToast.error("Error retrieving CRS");
            return [];
        }
    };

    useEffect(() => {
        getCRS(formState.coordinateReferenceSystem?.key ? formState.coordinateReferenceSystem?.key?.toString() : "");
    }, []);

    const { currencyOptions, pathOptions, productionTypeOptions, wceiOptions } = useInputOptions();

    const onSelectRig = (option: IDropdownOption) => {
        const rig = _.first(rigOptions.filter((x) => x.rigname === option.key)) as Rig;

        setFormState((prev) => ({
            ...prev,
            rig: rig.rigname,
            rigContractor: rig.contractor,
        }));
    };

    const onChangeTime = (_: React.FormEvent<IComboBox>, date: Date) => {
        setFormState((prev) => ({ ...prev, spudDate: date }));
    };

    const onChangeDate = (date: Date) => {
        setFormState((prev) => ({ ...prev, spudDate: date }));
    };

    const isProductionTypeDisabled = !(formState.ownership?.key === "C" || formState.ownership?.key === "O");

    // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    return (
        <div className="form-summary-form-container">
            <div className="flex-row">
                <span className="form-section-title">{FormSections.WELL_INFO.title}</span>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label="Spud date"
                        info={getInputInfoValue("well-info", "spud-date")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.DatePicker({
                            placeholder: "Select",
                            value: formState.spudDate,
                            onSelectDate: onChangeDate,
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label="Spud time"
                        info={getInputInfoValue("well-info", "spud-time")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TimePicker({
                            allowFreeform: true,
                            increments: 1,
                            autoComplete: "on",
                            onChange: onChangeTime,
                            defaultValue: formState.spudDate ?? new Date("January 1, 1970 00:00:00"),
                            useComboBoxAsMenuWidth: true,
                            placeholder: "Select a time",
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label="Production Type"
                        info={getInputInfoValue("well-info", "production-type")}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit || isProductionTypeDisabled,
                            placeholder: isProductionTypeDisabled ? "N/a" : "Select",
                            options: productionTypeOptions as IDropdownOption[],
                            selectedKey: isProductionTypeDisabled ? null : formState.productionType?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, productionType: option })),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label="Path"
                        info={getInputInfoValue("well-info", "path")}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: "Select",
                            options: pathOptions as IDropdownOption[],
                            selectedKey: formState.path?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, path: option })),
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label="Rig"
                        info={getInputInfoValue("well-info", "rig")}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            placeholder:
                                rigOptions?.length === 0
                                    ? formState.rig ?? " No rigs were found for this well"
                                    : "Select an option",
                            disabled: isReviewedEdit || rigOptions?.length === 0,
                            options: rigOptions.map((x) => ({
                                key: x.rigname,
                                text: x.rigname,
                            })),
                            selectedKey: formState.rig,
                            onChange: (event, option) => onSelectRig(option),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label="Rig Contractor"
                        info={getInputInfoValue("well-info", "rig-contractor")}
                        required={false}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: true,
                            maxLength: 255,
                            placeholder: rigOptions?.length === 0 ? "No rigs were found for this well" : "",
                            value: _.first(rigOptions.filter((x) => x.contractor === formState.rigContractor))
                                ?.contractor,
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label={`Water Depth/Ground Level [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={getInputInfoValue("well-info", "water-depth")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            type: "number",
                            maxLength: 255,
                            placeholder: "e.g.: 1,79",
                            value: formState.waterDepth ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, waterDepth: text })),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label={`KB Elevation [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={getInputInfoValue("well-info", "rotary-table")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            type: "number",
                            maxLength: 255,
                            placeholder: "e.g.: 9,71",
                            value: formState.rotaryTable ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, rotaryTable: text })),
                        })}
                    />
                </div>
            </div>

            <hr style={{ marginTop: "2em", marginBottom: "2rem" }} />

            <span className="form-section-title">{FormSections.GEO_WELLHEAD_INFO.title}</span>

            <div className="field-row">
                <div className="field-50">
                    <div className="field-row">
                        <div className="field-50 ">
                            <FluentUIDecorator
                                label="Latitude"
                                info={getInputInfoValue("well-info", "latitude")}
                                required={false}
                                errorMessage={(window as any)["highlight-errors"] === 1 ? "Incorrect value" : undefined}
                                fluentComponent={FluentUIDecoratorTypes.TextField({
                                    disabled: formState.coordinateFormat !== CoordinateFormatType.DD,
                                    type: "number",
                                    placeholder: "ex 45,481551",
                                    value: formState.latitude ?? "",
                                    onChange: (event, text) => setFormState((prev) => ({ ...prev, latitude: text })),
                                })}
                            />
                        </div>
                        <div className="field-50">
                            <FluentUIDecorator
                                label="Longitude"
                                info={getInputInfoValue("well-info", "longitude")}
                                required={false}
                                fluentComponent={FluentUIDecoratorTypes.TextField({
                                    disabled: formState.coordinateFormat !== CoordinateFormatType.DD,
                                    type: "number",
                                    placeholder: "ex 9,185632",
                                    value: formState.longitude ?? "",
                                    onChange: (event, text) => setFormState((prev) => ({ ...prev, longitude: text })),
                                })}
                            />
                        </div>
                    </div>

                    {formState.coordinateFormat !== CoordinateFormatType.DD && (
                        <div className="field-row">
                            <div className="field-50">
                                <DegreesMinutesSecondsInput
                                    defaultDecimalDegree={formState.latitude}
                                    setDecimalDegree={(value: string) => {
                                        setFormState((prev) => ({ ...prev, latitude: value }));
                                    }}
                                />
                            </div>
                            <div className="field-50 ">
                                <DegreesMinutesSecondsInput
                                    defaultDecimalDegree={formState.longitude}
                                    setDecimalDegree={(value: string) => {
                                        setFormState((prev) => ({ ...prev, longitude: value }));
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {formState.coordinateFormat !== CoordinateFormatType.DD && (
                        <span>Decimal degrees ex. N 45.481603° E 9.185757°</span>
                    )}
                    {formState.coordinateFormat !== CoordinateFormatType.DMS && (
                        <span>Degrees with minutes and decimal seconds ex. N 45° 28' 53.7708" E 9° 11' 8.72752"</span>
                    )}

                    <div className="field-row">
                        <div className="field-50">
                            <Dropdown
                                label="Latitude Direction"
                                options={[
                                    {
                                        key: "N",
                                        text: "North",
                                    },
                                    {
                                        key: "S",
                                        text: "South",
                                    },
                                ]}
                                selectedKey={formState.latDirection}
                                onChange={(event, option) => {
                                    setFormState((prev) => ({
                                        ...prev,
                                        latDirection: option.key.toString(),
                                    }));
                                }}
                            />
                        </div>
                        <div className="field-50">
                            <Dropdown
                                label="Longitude Direction"
                                options={[
                                    {
                                        key: "E",
                                        text: "East",
                                    },
                                    {
                                        key: "W",
                                        text: "West",
                                    },
                                ]}
                                selectedKey={formState.lonDirection}
                                onChange={(event, option) => {
                                    setFormState((prev) => ({
                                        ...prev,
                                        lonDirection: option.key.toString(),
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    <div className="field-row">
                        <div className="field-50">
                            <FluentUIDecorator
                                label="Coordinates type"
                                info={getInputInfoValue("well-info", "coordinate-type")}
                                required={true}
                                errorMessage={
                                    (window as any)["highlight-errors"] === 1 ? "This field is required" : undefined
                                }
                                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                                    placeholder: "Select",
                                    options: coordinateTypes,
                                    selectedKey: formState.coordinateFormat,
                                    onChange: (event, option) =>
                                        setFormState((prev) => ({
                                            ...prev,
                                            coordinateFormat: +option.key,
                                        })),
                                })}
                            />
                        </div>
                        <div className="field-50">
                            <FluentUIDecorator
                                label="CRS"
                                info={getInputInfoValue("well-info", "CRS")}
                                required={crsRequired}
                                errorMessage={
                                    (window as any)["highlight-errors"] === 1 ? "This field is required" : undefined
                                }
                                fluentComponent={FluentUIDecoratorTypes.TagPicker({
                                    // disabled: isReviewedEdit,
                                    pickerSuggestionsProps: {
                                        suggestionsHeaderText: "Suggested CRS",
                                        noResultsFoundText: "No CRS found",
                                    },
                                    inputProps: {
                                        placeholder: "Please start typing a CRS name to get suggestions…",
                                    },
                                    onResolveSuggestions: function (
                                        filter: string,
                                        selectedItems?: ITag[]
                                    ): ITag[] | PromiseLike<ITag[]> {
                                        return getCRS(filter).then((res) =>
                                            res.map((x) => ({
                                                key: x.coord_ref_sys_cd,
                                                name: x.coord_ref_sys_name,
                                            }))
                                        );
                                    },
                                    onItemSelected: (selectItem: ITag) => selectItem,
                                    onChange: (items: ITag[]) => {
                                        setFormState((prev) => ({
                                            ...prev,
                                            coordinateReferenceSystem: items.length === 0 ? null : _.first(items),
                                        }));
                                    },
                                    resolveDelay: 500,
                                    itemLimit: 1,
                                    defaultSelectedItems: formState.coordinateReferenceSystem
                                        ? [formState.coordinateReferenceSystem]
                                        : [],
                                })}
                            />
                        </div>
                    </div>

                    <div className="field-row">
                        <div className="field-100">
                            <FluentUIDecorator
                                label="Additional Information"
                                info={getInputInfoValue("well-info", "additional-information")}
                                fluentComponent={FluentUIDecoratorTypes.TextField({
                                    // disabled: isReviewedEdit,
                                    maxLength: 255,
                                    placeholder: "e.g.: Datum, CRS Transform…",
                                    value: formState.coordinateAdditionalInfo ?? "",
                                    onChange: (event, text) =>
                                        setFormState((prev) => ({ ...prev, coordinateAdditionalInfo: text })),
                                })}
                            />
                        </div>
                    </div>
                </div>

                <div className={`field-50 leaflet-container ${!isMapEnabled && "disabled-leaflet-container"}`}>
                    <MapContainer center={[0, 0]} zoom={13} scrollWheelZoom={isMapEnabled} dragging={isMapEnabled}>
                        <ChangeView center={center} zoom={zoom} disabled={!isMapEnabled} />
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {isMapEnabled && (
                            <Marker position={center}>
                                <Popup>The current chosen location</Popup>
                            </Marker>
                        )}
                    </MapContainer>
                </div>
            </div>

            <hr style={{ marginTop: "2em", marginBottom: "2rem" }} />

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label={`Planned Total Depth/TVDSS [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={getInputInfoValue("well-info", "tvdss")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: "number",
                            maxLength: 255,
                            placeholder: "e.g.: 3507,89",
                            value: formState.plannedTVDSS ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, plannedTVDSS: text })),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label={`Planned Total Depth/MD [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={getInputInfoValue("well-info", "md")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: "number",
                            maxLength: 255,
                            placeholder: "e.g.: 3515",
                            value: formState.plannedMD ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, plannedMD: text })),
                        })}
                    />
                </div>
            </div>
            <div className="field-row">
                <div className="field-33">
                    <FluentUIDecorator
                        label="AFE Cost"
                        info={getInputInfoValue("well-info", "afe-type")}
                        required={true}
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: "Select",
                            options: currencyOptions as IDropdownOption[],
                            selectedKey: formState.afeCostType?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, afeCostType: option })),
                        })}
                    />
                </div>
                <div className="field-33">
                    <FluentUIDecorator
                        label=""
                        info={getInputInfoValue("well-info", "afe-cost")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: "number",
                            maxLength: 255,
                            placeholder: "e.g.: 47,7 M$",
                            value: formState.afeCostValue ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, afeCostValue: text })),
                        })}
                    />
                </div>
                <div className="field-33">
                    <FluentUIDecorator
                        label="AFE Days"
                        info={getInputInfoValue("well-info", "afe-days")}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: "number",
                            maxLength: 255,
                            placeholder: "e.g.: 35,4",
                            value: formState.afeDays ?? "",
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, afeDays: text })),
                        })}
                    />
                </div>
            </div>
            <div className="field-row">
                <div className="field-100">
                    <FluentUIDecorator
                        label="WCEI"
                        info={getInputInfoValue("well-info", "wcei")}
                        required
                        errorMessage={(window as any)["highlight-errors"] === 1 ? "This field is required" : undefined}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: "Select",
                            options: wceiOptions as IDropdownOption[],
                            selectedKey: formState.wcei?.key,
                            onChange: (event, option) => {
                                setFormState((prev) => ({ ...prev, wcei: option }));
                            },
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default WellInformation;
