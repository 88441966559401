export const INPUT_INFO_URLS = {
    BASE_URL: "/InputInfo",
    SET_INPUT_INFO: "/InputInfo/set",
};
export const INPUT_OPTIONS_URLS = {
    BASE_URL: "/InputOptions",
    SET_INPUT_OPTIONS: "/InputOptions/set",
    GET_FORMATION_AGE: "/InputOptions/formation-age"
};
export const USERS_URLS = {
    GET_USERS: "/user/users",
    GET_APPROVERS: "/user/approvers",
    GET_COUNTRY_TIGHT_USERS: "/user/country-tight-users?wellCode={wellCode}&countryCode={countryCode}",
    GET_USER_URL: "/user",
    USER: "/user/{userId}",
    GRAPH_USERS: "/user/graph-users?search={search}",
    UPDATE_ROLES: "/user/{id}/roles",
    SYNC_PERMISSIONS: "/user/sync",
    LOGOUT: "/user/logout",
};

export const AREA_URLS = {
    GET_AREAS: "/area/areas",
    AREA: "/area",
    DELETE_AREA: "/area/{areaId}",
};
export const RECIPIENT_URLS = {
    GET_RECIPIENTS: "/recipient/recipients",
    RECIPIENT: "/recipient",
    DELETE_RECIPIENT: "/recipient/{recipientId}",
    GET_COUNTRY_OWNERSHIP_RECIPIENTS:
        "/recipient/recipients-for-country-and-ownership?countryCode={countryCode}&ownership={ownership}",
};
export const DATA_OWNER_URLS = {
    GET_DATA_OWNERS: "/DataOwner",
    DATA_OWNER: "/DataOwner",
    DELETE_DATA_OWNER: "/DataOwner/{dataOwnerId}",
};

export const ACTIVITY_URLS = {
    GET_ACTIVITIES_BY_USER: "/Activities/user",
    GET_ACTIVITIES_BY_REF: "/Activities/reference?wellCode={wellCode}",
};

export const SPUD_LETTER_URLS = {
    GET_LIST: `/spudletter/getlist`,
    PERMISSIONS: `/spudletter/{wellCode}/permissions`,
    BY_WELLCODE: `/spudletter/{wellCode}`,
    CREATE: `/spudletter?wellCode={wellCode}`,
    SEND_FOR_APPROVAL: `/spudletter/request-approval`,
    SEND_FOR_REVIEW: `/spudletter/request-rewiew`,
    GET_INVALID_FIELDS: `/spudletter/{wellCode}/invalid-fields`,
    DISTRIBUTE: `/spudletter/{wellCode}/distribute`,
    NOTIFY_MISSING_DATA: `/spudletter/{wellCode}/notify-missing-data`,
    LOCK: `/spudletter/{wellCode}/lock`,
    UNLOCK: `/spudletter/{wellCode}/unlock`,
    APPLY_CHANGES: `/spudletter/changes/{wellCode}/apply`,
};

export const LATEST_EXTERNAL_DATA_URLS = {
    GET_CHANGES: `/LatestExternalData/{wellCode}`,
};

export const DOCUMENT_URLS = {
    MAINFILE_PREVIEW: "/document/{wellCode}/mainfile/pdfpreview",
    ADD_ATTACHMENT: "/document/{wellCode}/attachment",
    ATTACHMENT: "/document/{wellCode}/attachment/{attachmentId}",
};

export const INBOUND_URLS = {
    COUNTRIES: `/Inbound/countries`,
    WELLS: `/Inbound/wells`,
    ARIS_FIELDS: `/Inbound/fields`,
    PROSPECTS: `/Inbound/prospects`,
    RESERVOIR_FIELDS: `/Inbound/reservoir-fields?country={country}`,
    RESERVOIR_LEVELS: `/Inbound/reservoir-levels?reservoirFieldId={reservoirFieldId}`,
    COUNTRY_RESERVOIR_LEVELS: `/Inbound/country-reservoir-levels`,
    PRD_PROSPECT_TARGETS: `/Inbound/prd-jt-prspct-target`,
    TARGETS: `/Inbound/targets`,
    RIG_CONTRACTORS: `/Inbound/rig-contractors?wellId={wellId}`,
    PROSPECT_DATA_WITH_ARIS: "/Inbound/spud-letter?wellCode={wellCode}&arisFieldId={arisFieldId}",
    PROSPECT_DATA_WITH_PROSPECT: "/Inbound/spud-letter?wellCode={wellCode}&prospectId={prospectId}",
    CRS: "/Inbound/coordinate-ref-system",
    HYDROCARBON_PHASES: "/Inbound/hydrocarbon-phases",
};

export const WORKFLOW_URLS = {
    GET_LATEST: `/Workflow/{wellCode}/latest`,
};

export const EDIT_LOCK_URLS = {
    LOCK: `/SpudLetterEditLock/{wellCode}/lock`,
    UNLOCK: `/SpudLetterEditLock/{wellCode}/unlock`,
    GET_LOCK: `/SpudLetterEditLock/{wellCode}`,
    GET_ACTIVE_LOCKS: `/SpudLetterEditLock/active`,
};

export const TARGET_AGES_URLS = {
    GET_TARGET_AGES: "/TargetAges", //?selectedIds={selectedIds}',
};
