import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";

import { InputOptionsKeys } from "../Constants/InputOptionsNames";
import { InputOption, Option } from "../Models/Options";
import { GlobalState } from "../Reducers/RootReducer";
import { useNavigate } from "react-router-dom";
import AppRoutes, { concatUrl } from "./AppRoutes";

//custom hook outside the component to detect unmount
export function useIsMounted() {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    return () => isMounted.current;
}

//create your forceUpdate hook
export function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    // console.log('rerendering')
    return () => setValue((value) => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here
    // is better than directly setting `setValue(value + 1)`
}
type UseBooleanFunction = (initial: boolean) => [boolean, UseBooleanActions];

export type UseBooleanActions = {
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
    toggle: VoidFunction;
    setTrue: VoidFunction;
    setFalse: VoidFunction;
};

export const useBoolean: UseBooleanFunction = (initialValue: boolean) => {
    const [value, setValue] = useState<boolean>(initialValue);

    const toggle = () => {
        setValue((prev) => !prev);
    };
    const setTrue = () => {
        setValue(true);
    };
    const setFalse = () => {
        setValue(false);
    };

    return [value, { setValue, toggle, setTrue, setFalse }];
};

/**
 * A hook to access Spud Letter's Input Options.
 * Also returns a boolean loaded, which specifies if options are present or not
 * @returns An object with all the input options and a loaded property
 */
export const useInputOptions = () => {
    const options = useSelector<GlobalState, InputOption[]>((state) => state.form.options);

    let ownershipOptions: Option[] = [];
    let onshoreOptions: Option[] = [];
    let referenceSystemOptions: Option[] = [];
    let pathOptions: Option[] = [];
    let currencyOptions: Option[] = [];
    let mainFluidOptions: Option[] = [];
    let trapTypeOptions: Option[] = [];
    let productionTypeOptions: Option[] = [];
    let wceiOptions: Option[] = [];
    let targetAgeOptions: Option[] = [];
    let lithologyOptions: Option[] = [];
    let coordinateTypeOptions: Option[] = [
        { key: "dd", text: "DD" },
        { key: "dms", text: "DMS" },
    ];

    let loaded = !!options && options.length > 0;

    if (loaded) {
        ownershipOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.OWNERSHIP))?.options ?? [];
        onshoreOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.ONSHORE_OFFSHORE))?.options ?? [];
        referenceSystemOptions =
            _.first(options.filter((x) => x.name === InputOptionsKeys.REFERENCE_SYSTEM))?.options ?? [];
        pathOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.PATH))?.options ?? [];
        currencyOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.CURRENCY))?.options ?? [];
        mainFluidOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.MAIN_FLUID))?.options ?? [];
        trapTypeOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.TRAP_TYPE))?.options ?? [];
        productionTypeOptions =
            _.first(options.filter((x) => x.name === InputOptionsKeys.PRODUCTION_TYPE))?.options ?? [];
        wceiOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.WCEI))?.options ?? [];
        targetAgeOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.TARGET_NAME))?.options ?? [];
        lithologyOptions = _.first(options.filter((x) => x.name === InputOptionsKeys.LITHOLOGY))?.options ?? [];
    }

    return {
        ownershipOptions,
        onshoreOptions,
        referenceSystemOptions,
        pathOptions,
        currencyOptions,
        mainFluidOptions,
        trapTypeOptions,
        productionTypeOptions,
        wceiOptions,
        targetAgeOptions,
        lithologyOptions,
        coordinateTypeOptions,
        loaded,
    };
};

// export const useNavigateForm = (wellCode: string) => {
//     const navigate = useNavigate();

//     const navigateToFormRoute = (route: string) => {
//         navigate(concatUrl(AppRoutes.SPUD_LETTER_FORM_ROUTE, `${wellCode}${route}`));
//     };

//     return navigateToFormRoute;
// };

// export const useNavigateCustom = () => {
//     const navigate = useNavigate();

//     return (route: string, isAbsolute: boolean = false) => {
//         if (isAbsolute) navigate("/" + route);
//         else navigate(route);
//     };
// };
